.user-overlay__wrapper {
	display: grid;
	grid-template-columns: 370px 1fr;
	grid-template-rows: 100%;
}

.fill_balance {
	margin-bottom: 10px;
	padding: 10px 20px;

	color: #FAFAFA;
	border: none;
	border-radius: 10px;
	outline: none;
	background-color: var(--second-color);

	font-size: 21px;
	font-weight: 600;
	line-height: 25px;
}

.balance {
	display: block;

	width: 198px;
	margin-left: auto;

	text-align: right;

	color: var(--second-color);

	font-size: 14px;
	line-height: 16px;
}

.user__spinner {
	position: relative;

	height: 300px;
}

.user__spinner > div {
	position: absolute;
	top: 50%;
	left: 50%;

	transform: translate(-50%, -50%);
}

@media (min-width: 1200px) {
	.custom_container {
		max-width: 100%;
	}
}

@media (max-width: 992px) {
	.custom_container {
		width: 720px;
		margin: 0 auto;
	}

	.user-overlay__wrapper {
		grid-template-columns: 1fr;
		grid-template-rows: unset;
	}
}

@media (max-width: 768px) {
	.custom_container {
		width: 540px;
		margin: 0 auto;
		padding: 0 15px;
	}
}

@media (max-width: 576px) {
	.custom_container {
		width: 100%;
		margin: 0 auto;
		padding: 31px 15px;
	}
}
