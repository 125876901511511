.menu {
	min-height: 106px;

	background-color: #FFF;

	box-shadow: 0 6px 12px rgba(0, 0, 0, 0.06);
}

.menu__fixed {
	position: fixed;
	z-index: 9;
	top: 0;
	left: 0;

	width: 100%;

	background-color: rgba(250, 250, 250, 0.5);
	box-shadow: 0 6px 12px rgba(0, 0, 0, 6%);
	backdrop-filter: blur(10px);
}

.menu__wrapper {
	display: flex;
	align-items: center;
	justify-content: space-between;

	padding: 25px 0;

	transition: all 0.1s;
}

.menu__nav-wrapper,
.menu__nav-wrapper .menu__list {
	display: flex;
	align-items: center;
	justify-content: space-between;

	margin: 0;
	padding: 0;

	list-style-type: none;
}

.menu__list li {
	margin-right: 50px;
}

.menu__list li a {
	position: relative;

	text-decoration: none;

	color: var(--second-color);

	font-size: 17px;

	font-weight: 500;
	line-height: 20px;
}

.menu__list li a img {
	display: none;
}

.menu__list li a span,
.menu__list li a span svg {
	display: none;
}

.menu__list li a::after {
	position: absolute;
	right: 0;
	left: 0;

	display: block;

	width: 0;
	height: 2px;
	margin: 0 auto;

	content: '';
	transition: var(--transition-anime);

	opacity: 0;
	background-color: var(--second-color);
}

.menu__list li:hover a::after {
	width: 100%;

	opacity: 1;
}

.menu__list li a.active_link::after {
	width: 100%;

	opacity: 1;
}

.menu__dropdown.active {
	display: block;

	animation: dropdown 0.2s ease;
}

.menu__dropdown li {
	cursor: pointer;
	user-select: none;
	text-transform: uppercase;

	color: #FAFAFA;

	font-size: 17px;
	font-weight: 500;
	line-height: 20px;
}

@keyframes dropdown {
	from {
		transform: translateY(5%);
	}

	to {
		transform: translateY(0);
	}
}

.menu__dropdown {
	position: absolute;
	left: -3px;

	display: none;

	margin: 0;
	padding: 4px 8px;

	list-style-type: none;

	cursor: pointer;
	user-select: none;

	border-radius: 10px;
	background-color: var(--main-color);
}

.menu__lang {
	position: relative;
	z-index: 1;

	width: 45px;
	margin-right: 30px;
}

.menu__lang span {
	cursor: pointer;
	user-select: none;
	transition: var(--transition-anime);
	text-transform: uppercase;

	color: var(--second-color);

	font-size: 17px;
	font-weight: 600;
}

.menu__lang span svg {
	margin-left: 5px;
}

.menu__button {
	padding: 8px 20px;

	transition: var(--transition-anime);
	text-decoration: none;

	letter-spacing: 0.02em;
	text-transform: uppercase;

	color: white;
	border: 1px solid transparent;
	border-radius: 10px;
	background-color: var(--second-color);

	font-size: 14px;
	font-weight: 600;
	line-height: 17px;
}

.menu__button:hover {
	color: var(--second-color);
	border: 1px solid var(--second-color);
	background-color: transparent;
}

.menu__button svg {
	fill: white;

	display: none;

	width: 20px;
	height: 15px;
	margin-right: 5px;
	margin-bottom: 2px;
}

.menu__button:hover svg {
	fill: var(--second-color);
}

.menu__user {
	margin-right: 30px;

	cursor: pointer;

	border-radius: 100%;
	background-color: #ECEFF1;
}

.menu__user a {
	display: block;

	width: 30px;
	height: 30px;

	border-radius: 100%;
}

.menu__user a img {
	width: 100%;
	height: 100%;

	border-radius: 100%;

	object-fit: cover;
	object-position: center;
}

.menu__open {
	display: none;
}

.menu__close {
	display: none;
}

@media (max-width: 1200px) {
	.menu__user {
		margin-right: 25px;
	}

	.menu__user a svg {
		width: 22px;
		height: 26px;
	}

	.menu__button {
		padding: 5px 13px;

		font-size: 14px;
	}

	.menu__lang {
		width: 40px;
		margin-right: 25px;
	}

	.menu__list li {
		margin-right: 25px;
	}

	.menu__list li a {
		font-size: 15px;
	}
}

@media (max-width: 992px) {
	.menu__list li a span,
	.menu__list li a span svg {
		display: inline-block;
	}

	.menu__button {
		margin-right: 5px;
		padding: 0;

		text-transform: capitalize;

		color: var(--second-color);
		border: 1px solid transparent;
		background-color: unset;

		font-size: 18px;

		font-weight: 500;
		line-height: 27px;
	}

	.menu__button:hover {
		border: 1px solid transparent;
		background-color: unset;
	}

	.menu__button svg {
		fill: var(--second-color);

		display: unset;

		width: 26px;
		height: 30px;
		margin-right: 10px;
	}

	.menu__dropdown {
		position: absolute;
		top: -221px;
		left: 196px;

		display: flex;

		background-color: transparent;
	}

	.menu__user {
		margin-right: 0;
		margin-bottom: 20px;
		padding: 0;

		border-radius: unset;
		background-color: transparent;
	}

	.menu__user a {
		position: relative;

		width: 24px;
		height: 26px;
	}

	.menu__user a::after {
		position: absolute;
		top: 8px;
		left: 36px;

		width: max-content;

		content: attr(data-label);

		color: var(--second-color);

		font-size: 18px;
		font-weight: 500;
		line-height: 18px;
	}

	.menu__user a {
		display: block;

		text-decoration: none;
	}

	.menu__user a svg {
		width: 29px;
		height: 24px;
		fill: var(--second-color);

		margin-right: 18px;
	}

	.menu__list li a.active_link {
		color: var(--main-color);
	}

	.menu__list li a.active_link img {
		filter: invert(47%) sepia(97%) saturate(473%) hue-rotate(338deg) brightness(104%) contrast(89%);
	}

	.menu__list li a.active_link svg {
		fill: var(--main-color);
	}

	.menu__user a.active::after {
		color: var(--main-color);
	}

	.menu__button.active {
		color: var(--main-color);
	}

	.menu__button.active svg {
		fill: var(--main-color);
	}

	.menu__dropdown li {
		padding-right: 10px;

		transition: all 0.5s;

		color: black;
	}

	.menu__dropdown li.active {
		color: var(--main-color);
	}

	.menu__open {
		display: block;
	}

	.menu__close {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.menu__close ul {
		display: flex;
		align-items: center;

		margin: 0;
		padding: 0;

		list-style-type: none;
	}

	.menu__close ul li {
		margin-left: 20px;

		text-transform: uppercase;

		color: var(--second-color);

		font-size: 17px;
		font-weight: 500;
		line-height: 20px;
	}

	.menu__close ul li.active {
		color: var(--main-color);
	}

	.menu__lang {
		display: none;
	}

	.menu__dropdown.active {
		display: flex;
	}

	.menu__nav-wrapper {
		align-items: initial;
		flex-direction: column;
		justify-content: flex-start;

		width: 275px;
		height: 100%;
		padding: 25px 12px;

		background-color: #FFF;
	}

	.menu__nav-wrapper .menu__list {
		align-items: initial;
		flex-direction: column;
		justify-content: initial;

		margin-top: 30px;
	}

	.menu__list li {
		margin-bottom: 20px;
	}

	.menu__list li a {
		display: flex;
		align-items: center;

		font-size: 19px;
	}

	.menu__list li a::after {
		content: unset;
	}

	.menu__list li a img {
		display: block;

		width: 24px;
		height: 30px;
		margin-right: 12px;
		filter: invert(0.8);
		object-fit: contain;
	}

	.menu__list li a span {
		margin-right: 12px;
	}

	.menu__list li a span svg {
		width: 24px;
		height: 30px;
	}

	.menu__nav {
		position: fixed;
		z-index: 3;
		top: 0;
		left: -100%;

		visibility: hidden;

		width: 100%;
		height: 100vh;

		transition: all 0.3s ease;

		opacity: 0;
	}

	.nav__overlay {
		position: fixed;
		z-index: -1;
		top: 0;
		left: 0;

		visibility: hidden;

		width: 100%;
		height: 100vh;

		transition: all 0.3s ease;

		opacity: 0;
		background-color: rgba(0, 0, 0, 0.5);
	}

	.menu__nav.active {
		left: 0;

		visibility: visible;

		opacity: 1;
	}

	.menu__nav.active .nav__overlay {
		visibility: visible;

		opacity: 1;
	}
}

@media (max-width: 576px) {
	.menu {
		min-height: 75px;
	}

	.menu__wrapper {
		padding: 9px 0;
	}
}
