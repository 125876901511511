.footer {
  padding: 60px 0;

  background-color: #f8f8f8;
}

.footer__wrapper {
  display: grid;
  grid-template-columns: 150px 236px 308px 300px;
  grid-column-gap: 123px;
  column-gap: 100px;
}

.footer_logo {
  display: block;
}

.footer_logo svg {
  width: 98px;
  height: 108px;
}

.footer_licence {
  margin-top: 12px;

  text-align: left;

  color: #3b2f43;

  font-size: 11px;
  font-weight: 500;
  line-height: 12px;
}

.footer_licence:last-child {
  margin-top: 5px;
}

.footer_menu {
  margin: 0;
  padding: 0;

  list-style-type: none;
}

.footer_menu li a {
  text-decoration: none;

  color: var(--second-color);

  font-size: 17px;
  font-weight: 500;
  line-height: 20px;
}

.footer_menu li {
  padding-bottom: 12px;
}

.footer_menu li:last-child {
  padding-bottom: 0;
}

.social_title {
  color: var(--second-color);

  font-size: 17px;

  font-weight: 500;
  line-height: 28px;
}

.social {
  display: flex;
  align-items: center;
  gap: 6px;

  margin-top: 15px;
}

.social a {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 30px;
  height: 30px;
  margin-right: 12px;

  transition: var(--transition-anime);
  text-align: center;
  vertical-align: middle;

  border: 1px solid transparent;
  border-radius: 100%;
}

.social a:hover {
  transform: translateY(-8%);
}

.social a:hover svg {
  fill: black;
}

.footer_phone,
.footer_mail {
  display: block;

  transition: var(--transition-anime);
  text-decoration: none;

  color: var(--second-color);

  font-size: 25px;

  font-weight: 600;
  line-height: 30px;
}

.footer_phone:hover,
.footer_mail:hover {
  color: var(--main-color);
}

.footer_mail {
  margin-top: 8px;

  font-size: 25px;
  line-height: 32px;
}

.datasitelogo {
  display: flex;
  align-items: center;

  margin-top: 18px;

  text-decoration: none;
}

.datasitelogo span {
  margin-left: 10px;
  padding-right: 14px;

  color: var(--second-color);

  font-size: 12px;

  font-weight: normal;
  line-height: 16px;
}

@media (max-width: 1399px) {
  .footer__wrapper {
    column-gap: 62px;
  }
}

@media (max-width: 1200px) {
  .footer__wrapper {
    column-gap: 54px;
    grid-template-columns: 139px 150px 240px 271px;
  }

  .footer_menu li a {
    font-size: 15px;
  }

  .footer_menu li {
    padding-bottom: 7px;
  }

  .social_title {
    font-size: 14px;
    line-height: 24px;
  }

  .footer_mail {
    font-size: 22px;
    line-height: 23px;
  }
}

.call {
  color: var(--second-color);

  font-size: 17px;
  font-weight: 500;
  line-height: 20px;
}

@media (max-width: 992px) {
  .footer__wrapper {
    grid-template-columns: repeat(2, 1fr);
    row-gap: 60px;
  }
}

@media (max-width: 576px) {
  .footer {
    padding: 30px 0;
  }

  .footer_menu li {
    padding-bottom: 4px;
  }

  .footer_menu li a {
    font-size: 14px;
  }

  .footer_licence {
    text-align: center;
  }

  .footer__wrapper div {
    justify-self: center;

    text-align: center;
  }

  .footer_logo,
  .footer_menu li {
    text-align: center;
  }

  .footer__wrapper {
    grid-template-columns: 1fr;
    row-gap: 15px;
  }

  .social {
    justify-content: center;
  }

  .social_wrapper {
    width: 276px;

    text-align: center;
  }

  .footer_phone {
    font-size: 16px;
  }

  .call {
    margin-bottom: 0;

    font-size: 15px;
  }

  .footer_mail {
    margin-top: 0;

    font-size: 16px;
    line-height: 18px;
  }
}
