.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
}

.overlay {
  background-color: #0000009b;
  width: 100%;
  height: 100%;
}

.content {
  padding: 60px 120px;
  background: #fff;
  border-radius: 0 0 16px 16px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.btn {
  padding: 8px 20px;

  transition: var(--transition-anime);
  text-decoration: none;

  letter-spacing: 0.02em;
  text-transform: uppercase;

  color: white;
  border: 1px solid transparent;
  border-radius: 10px;
  background-color: var(--second-color);

  font-size: 14px;
  font-weight: 600;
  line-height: 17px;

  margin: 15px auto 0 auto;
}
