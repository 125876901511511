/*!
 * Bootstrap Reboot v5.0.2 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 * Forked from Normalize.css, licensed MIT (https://github.com/necolas/normalize.css/blob/master/LICENSE.md)
 */*,
::after,
::before { box-sizing: border-box; }

@media (prefers-reduced-motion: no-preference) { :root { scroll-behavior: smooth; } }

body {
	margin: 0;

	color: #212529;
	background-color: #FFF;

	font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	-webkit-text-size-adjust: 100%;
	-webkit-tap-highlight-color: transparent;
}

hr {
	margin: 1rem 0;

	opacity: 0.25;
	color: inherit;
	border: 0;
	background-color: currentColor;
}
hr:not([size]) { height: 1px; }

h1,
h2,
h3,
h4,
h5,
h6 {
	margin-top: 0;
	margin-bottom: 0.5rem;

	font-weight: 500;
	line-height: 1.2;
}
h1 { font-size: calc(1.375rem + 1.5vw); }

@media (min-width: 1200px) { h1 { font-size: 2.5rem; } }
h2 { font-size: calc(1.325rem + 0.9vw); }

@media (min-width: 1200px) { h2 { font-size: 2rem; } }
h3 { font-size: calc(1.3rem + 0.6vw); }

@media (min-width: 1200px) { h3 { font-size: 1.75rem; } }
h4 { font-size: calc(1.275rem + 0.3vw); }

@media (min-width: 1200px) { h4 { font-size: 1.5rem; } }
h5 { font-size: 1.25rem; }
h6 { font-size: 1rem; }
p { margin-top: 0; margin-bottom: 1rem; }

abbr[data-bs-original-title],
abbr[title] { cursor: help; -webkit-text-decoration: underline dotted; text-decoration: underline dotted; -webkit-text-decoration-skip-ink: none; text-decoration-skip-ink: none; }

address {
	margin-bottom: 1rem;

	font-style: normal;
	line-height: inherit;
}

ol,
ul { padding-left: 2rem; }

dl,
ol,
ul { margin-top: 0; margin-bottom: 1rem; }

ol ol,
ol ul,
ul ol,
ul ul { margin-bottom: 0; }
dt { font-weight: 700; }
dd { margin-bottom: 0.5rem; margin-left: 0; }
blockquote { margin: 0 0 1rem; }

b,
strong { font-weight: bolder; }
small { font-size: 0.875em; }

mark {
	padding: 0.2em;

	background-color: #FCF8E3;
}

sub,
sup {
	position: relative;

	vertical-align: baseline;

	font-size: 0.75em;
	line-height: 0;
}
sub { bottom: -0.25em; }
sup { top: -0.5em; }

a {
	text-decoration: underline;

	color: #0D6EFD;
}
a:hover { color: #0A58CA; }

a:not([href]):not([class]),
a:not([href]):not([class]):hover {
	text-decoration: none;

	color: inherit;
}

code,
kbd,
pre,
samp { font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; font-size: 1em; direction: ltr; unicode-bidi: bidi-override; }

pre {
	display: block;
	overflow: auto;

	margin-top: 0;
	margin-bottom: 1rem;

	font-size: 0.875em;
}

pre code {
	word-break: normal;

	color: inherit;

	font-size: inherit;
}

code {
	word-wrap: break-word;

	color: #D63384;

	font-size: 0.875em;
}
a > code { color: inherit; }

kbd {
	padding: 0.2rem 0.4rem;

	color: #FFF;
	border-radius: 0.2rem;
	background-color: #212529;

	font-size: 0.875em;
}

kbd kbd {
	padding: 0;

	font-size: 1em;
	font-weight: 700;
}
figure { margin: 0 0 1rem; }

img,
svg { vertical-align: middle; }

table {
	caption-side: bottom;

	border-collapse: collapse;
}

caption {
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;

	text-align: left;

	color: #6C757D;
}
th { text-align: inherit; text-align: -webkit-match-parent; }

tbody,
td,
tfoot,
th,
thead,
tr { border-width: 0; border-style: solid; border-color: inherit; }
label { display: inline-block; }
button { border-radius: 0; }
button:focus:not(:focus-visible) { outline: 0; }

button,
input,
optgroup,
select,
textarea {
	margin: 0;

	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
}

button,
select { text-transform: none; }
[role=button] { cursor: pointer; }
select { word-wrap: normal; }
select:disabled { opacity: 1; }
[list]::-webkit-calendar-picker-indicator { display: none; }

[type=button],
[type=reset],
[type=submit],
button { -webkit-appearance: button; }

[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled),
button:not(:disabled) { cursor: pointer; }

::-moz-focus-inner {
	padding: 0;

	border-style: none;
}
textarea { resize: vertical; }

fieldset {
	min-width: 0;
	margin: 0;
	padding: 0;

	border: 0;
}

legend {
	float: left;

	width: 100%;
	margin-bottom: 0.5rem;
	padding: 0;

	font-size: calc(1.275rem + 0.3vw);
	line-height: inherit;
}

@media (min-width: 1200px) { legend { font-size: 1.5rem; } }
legend + * { clear: left; }

::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-year-field { padding: 0; }
::-webkit-inner-spin-button { height: auto; }
[type=search] { outline-offset: -2px; -webkit-appearance: textfield; }
::-webkit-search-decoration { -webkit-appearance: none; }
::-webkit-color-swatch-wrapper { padding: 0; }
::file-selector-button { font: inherit; }
::-webkit-file-upload-button { font: inherit; -webkit-appearance: button; }
output { display: inline-block; }
iframe { border: 0; }

summary {
	display: list-item;

	cursor: pointer;
}
progress { vertical-align: baseline; }
[hidden] { display: none !important; }

/*!
 * Bootstrap Grid v5.0.2 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl { width: 100%; margin-right: auto; margin-left: auto; padding-right: 0.75rem; padding-right: var(--bs-gutter-x, 0.75rem); padding-left: 0.75rem; padding-left: var(--bs-gutter-x, 0.75rem); }

@media (min-width: 576px) {
	.container,
	.container-sm { max-width: 540px; }
}

@media (min-width: 768px) {
	.container,
	.container-md,
	.container-sm { max-width: 720px; }
}

@media (min-width: 992px) {
	.container,
	.container-lg,
	.container-md,
	.container-sm { max-width: 960px; }
}

@media (min-width: 1200px) {
	.container,
	.container-lg,
	.container-md,
	.container-sm,
	.container-xl { max-width: 1140px; }
}

@media (min-width: 1400px) {
	.container,
	.container-lg,
	.container-md,
	.container-sm,
	.container-xl,
	.container-xxl { max-width: 1320px; }
}

.row {
	--bs-gutter-x: 1.5rem;
	--bs-gutter-y: 0;
	display: flex;
	flex-wrap: wrap;

	margin-top: calc(var(--bs-gutter-y) * -1);
	margin-right: calc(var(--bs-gutter-x) * -0.5);
	margin-left: calc(var(--bs-gutter-x) * -0.5);
}

.row > * {
	flex-shrink: 0;

	box-sizing: border-box;
	width: 100%;
	max-width: 100%;
	margin-top: var(--bs-gutter-y);
	padding-right: calc(var(--bs-gutter-x) * 0.5);
	padding-left: calc(var(--bs-gutter-x) * 0.5);
}
.col { flex: 1 0; }

.row-cols-auto > * {
	flex: 0 0 auto;

	width: auto;
}

.row-cols-1 > * {
	flex: 0 0 auto;

	width: 100%;
}

.row-cols-2 > * {
	flex: 0 0 auto;

	width: 50%;
}

.row-cols-3 > * {
	flex: 0 0 auto;

	width: 33.3333333333%;
}

.row-cols-4 > * {
	flex: 0 0 auto;

	width: 25%;
}

.row-cols-5 > * {
	flex: 0 0 auto;

	width: 20%;
}

.row-cols-6 > * {
	flex: 0 0 auto;

	width: 16.6666666667%;
}

@media (min-width: 576px) {
	.col-sm { flex: 1 0; }

	.row-cols-sm-auto > * {
		flex: 0 0 auto;

		width: auto;
	}

	.row-cols-sm-1 > * {
		flex: 0 0 auto;

		width: 100%;
	}

	.row-cols-sm-2 > * {
		flex: 0 0 auto;

		width: 50%;
	}

	.row-cols-sm-3 > * {
		flex: 0 0 auto;

		width: 33.3333333333%;
	}

	.row-cols-sm-4 > * {
		flex: 0 0 auto;

		width: 25%;
	}

	.row-cols-sm-5 > * {
		flex: 0 0 auto;

		width: 20%;
	}

	.row-cols-sm-6 > * {
		flex: 0 0 auto;

		width: 16.6666666667%;
	}
}

@media (min-width: 768px) {
	.col-md { flex: 1 0; }

	.row-cols-md-auto > * {
		flex: 0 0 auto;

		width: auto;
	}

	.row-cols-md-1 > * {
		flex: 0 0 auto;

		width: 100%;
	}

	.row-cols-md-2 > * {
		flex: 0 0 auto;

		width: 50%;
	}

	.row-cols-md-3 > * {
		flex: 0 0 auto;

		width: 33.3333333333%;
	}

	.row-cols-md-4 > * {
		flex: 0 0 auto;

		width: 25%;
	}

	.row-cols-md-5 > * {
		flex: 0 0 auto;

		width: 20%;
	}

	.row-cols-md-6 > * {
		flex: 0 0 auto;

		width: 16.6666666667%;
	}
}

@media (min-width: 992px) {
	.col-lg { flex: 1 0; }

	.row-cols-lg-auto > * {
		flex: 0 0 auto;

		width: auto;
	}

	.row-cols-lg-1 > * {
		flex: 0 0 auto;

		width: 100%;
	}

	.row-cols-lg-2 > * {
		flex: 0 0 auto;

		width: 50%;
	}

	.row-cols-lg-3 > * {
		flex: 0 0 auto;

		width: 33.3333333333%;
	}

	.row-cols-lg-4 > * {
		flex: 0 0 auto;

		width: 25%;
	}

	.row-cols-lg-5 > * {
		flex: 0 0 auto;

		width: 20%;
	}

	.row-cols-lg-6 > * {
		flex: 0 0 auto;

		width: 16.6666666667%;
	}
}

@media (min-width: 1200px) {
	.col-xl { flex: 1 0; }

	.row-cols-xl-auto > * {
		flex: 0 0 auto;

		width: auto;
	}

	.row-cols-xl-1 > * {
		flex: 0 0 auto;

		width: 100%;
	}

	.row-cols-xl-2 > * {
		flex: 0 0 auto;

		width: 50%;
	}

	.row-cols-xl-3 > * {
		flex: 0 0 auto;

		width: 33.3333333333%;
	}

	.row-cols-xl-4 > * {
		flex: 0 0 auto;

		width: 25%;
	}

	.row-cols-xl-5 > * {
		flex: 0 0 auto;

		width: 20%;
	}

	.row-cols-xl-6 > * {
		flex: 0 0 auto;

		width: 16.6666666667%;
	}
}

@media (min-width: 1400px) {
	.col-xxl { flex: 1 0; }

	.row-cols-xxl-auto > * {
		flex: 0 0 auto;

		width: auto;
	}

	.row-cols-xxl-1 > * {
		flex: 0 0 auto;

		width: 100%;
	}

	.row-cols-xxl-2 > * {
		flex: 0 0 auto;

		width: 50%;
	}

	.row-cols-xxl-3 > * {
		flex: 0 0 auto;

		width: 33.3333333333%;
	}

	.row-cols-xxl-4 > * {
		flex: 0 0 auto;

		width: 25%;
	}

	.row-cols-xxl-5 > * {
		flex: 0 0 auto;

		width: 20%;
	}

	.row-cols-xxl-6 > * {
		flex: 0 0 auto;

		width: 16.6666666667%;
	}
}

.col-auto {
	flex: 0 0 auto;

	width: auto;
}

.col-1 {
	flex: 0 0 auto;

	width: 8.33333333%;
}

.col-2 {
	flex: 0 0 auto;

	width: 16.66666667%;
}

.col-3 {
	flex: 0 0 auto;

	width: 25%;
}

.col-4 {
	flex: 0 0 auto;

	width: 33.33333333%;
}

.col-5 {
	flex: 0 0 auto;

	width: 41.66666667%;
}

.col-6 {
	flex: 0 0 auto;

	width: 50%;
}

.col-7 {
	flex: 0 0 auto;

	width: 58.33333333%;
}

.col-8 {
	flex: 0 0 auto;

	width: 66.66666667%;
}

.col-9 {
	flex: 0 0 auto;

	width: 75%;
}

.col-10 {
	flex: 0 0 auto;

	width: 83.33333333%;
}

.col-11 {
	flex: 0 0 auto;

	width: 91.66666667%;
}

.col-12 {
	flex: 0 0 auto;

	width: 100%;
}
.offset-1 { margin-left: 8.33333333%; }
.offset-2 { margin-left: 16.66666667%; }
.offset-3 { margin-left: 25%; }
.offset-4 { margin-left: 33.33333333%; }
.offset-5 { margin-left: 41.66666667%; }
.offset-6 { margin-left: 50%; }
.offset-7 { margin-left: 58.33333333%; }
.offset-8 { margin-left: 66.66666667%; }
.offset-9 { margin-left: 75%; }
.offset-10 { margin-left: 83.33333333%; }
.offset-11 { margin-left: 91.66666667%; }

.g-0,
.gx-0 { --bs-gutter-x: 0; }

.g-0,
.gy-0 { --bs-gutter-y: 0; }

.g-1,
.gx-1 { --bs-gutter-x: 0.25rem; }

.g-1,
.gy-1 { --bs-gutter-y: 0.25rem; }

.g-2,
.gx-2 { --bs-gutter-x: 0.5rem; }

.g-2,
.gy-2 { --bs-gutter-y: 0.5rem; }

.g-3,
.gx-3 { --bs-gutter-x: 1rem; }

.g-3,
.gy-3 { --bs-gutter-y: 1rem; }

.g-4,
.gx-4 { --bs-gutter-x: 1.5rem; }

.g-4,
.gy-4 { --bs-gutter-y: 1.5rem; }

.g-5,
.gx-5 { --bs-gutter-x: 3rem; }

.g-5,
.gy-5 { --bs-gutter-y: 3rem; }

@media (min-width: 576px) {
	.col-sm-auto {
		flex: 0 0 auto;

		width: auto;
	}

	.col-sm-1 {
		flex: 0 0 auto;

		width: 8.33333333%;
	}

	.col-sm-2 {
		flex: 0 0 auto;

		width: 16.66666667%;
	}

	.col-sm-3 {
		flex: 0 0 auto;

		width: 25%;
	}

	.col-sm-4 {
		flex: 0 0 auto;

		width: 33.33333333%;
	}

	.col-sm-5 {
		flex: 0 0 auto;

		width: 41.66666667%;
	}

	.col-sm-6 {
		flex: 0 0 auto;

		width: 50%;
	}

	.col-sm-7 {
		flex: 0 0 auto;

		width: 58.33333333%;
	}

	.col-sm-8 {
		flex: 0 0 auto;

		width: 66.66666667%;
	}

	.col-sm-9 {
		flex: 0 0 auto;

		width: 75%;
	}

	.col-sm-10 {
		flex: 0 0 auto;

		width: 83.33333333%;
	}

	.col-sm-11 {
		flex: 0 0 auto;

		width: 91.66666667%;
	}

	.col-sm-12 {
		flex: 0 0 auto;

		width: 100%;
	}
	.offset-sm-0 { margin-left: 0; }
	.offset-sm-1 { margin-left: 8.33333333%; }
	.offset-sm-2 { margin-left: 16.66666667%; }
	.offset-sm-3 { margin-left: 25%; }
	.offset-sm-4 { margin-left: 33.33333333%; }
	.offset-sm-5 { margin-left: 41.66666667%; }
	.offset-sm-6 { margin-left: 50%; }
	.offset-sm-7 { margin-left: 58.33333333%; }
	.offset-sm-8 { margin-left: 66.66666667%; }
	.offset-sm-9 { margin-left: 75%; }
	.offset-sm-10 { margin-left: 83.33333333%; }
	.offset-sm-11 { margin-left: 91.66666667%; }

	.g-sm-0,
	.gx-sm-0 { --bs-gutter-x: 0; }

	.g-sm-0,
	.gy-sm-0 { --bs-gutter-y: 0; }

	.g-sm-1,
	.gx-sm-1 { --bs-gutter-x: 0.25rem; }

	.g-sm-1,
	.gy-sm-1 { --bs-gutter-y: 0.25rem; }

	.g-sm-2,
	.gx-sm-2 { --bs-gutter-x: 0.5rem; }

	.g-sm-2,
	.gy-sm-2 { --bs-gutter-y: 0.5rem; }

	.g-sm-3,
	.gx-sm-3 { --bs-gutter-x: 1rem; }

	.g-sm-3,
	.gy-sm-3 { --bs-gutter-y: 1rem; }

	.g-sm-4,
	.gx-sm-4 { --bs-gutter-x: 1.5rem; }

	.g-sm-4,
	.gy-sm-4 { --bs-gutter-y: 1.5rem; }

	.g-sm-5,
	.gx-sm-5 { --bs-gutter-x: 3rem; }

	.g-sm-5,
	.gy-sm-5 { --bs-gutter-y: 3rem; }
}

@media (min-width: 768px) {
	.col-md-auto {
		flex: 0 0 auto;

		width: auto;
	}

	.col-md-1 {
		flex: 0 0 auto;

		width: 8.33333333%;
	}

	.col-md-2 {
		flex: 0 0 auto;

		width: 16.66666667%;
	}

	.col-md-3 {
		flex: 0 0 auto;

		width: 25%;
	}

	.col-md-4 {
		flex: 0 0 auto;

		width: 33.33333333%;
	}

	.col-md-5 {
		flex: 0 0 auto;

		width: 41.66666667%;
	}

	.col-md-6 {
		flex: 0 0 auto;

		width: 50%;
	}

	.col-md-7 {
		flex: 0 0 auto;

		width: 58.33333333%;
	}

	.col-md-8 {
		flex: 0 0 auto;

		width: 66.66666667%;
	}

	.col-md-9 {
		flex: 0 0 auto;

		width: 75%;
	}

	.col-md-10 {
		flex: 0 0 auto;

		width: 83.33333333%;
	}

	.col-md-11 {
		flex: 0 0 auto;

		width: 91.66666667%;
	}

	.col-md-12 {
		flex: 0 0 auto;

		width: 100%;
	}
	.offset-md-0 { margin-left: 0; }
	.offset-md-1 { margin-left: 8.33333333%; }
	.offset-md-2 { margin-left: 16.66666667%; }
	.offset-md-3 { margin-left: 25%; }
	.offset-md-4 { margin-left: 33.33333333%; }
	.offset-md-5 { margin-left: 41.66666667%; }
	.offset-md-6 { margin-left: 50%; }
	.offset-md-7 { margin-left: 58.33333333%; }
	.offset-md-8 { margin-left: 66.66666667%; }
	.offset-md-9 { margin-left: 75%; }
	.offset-md-10 { margin-left: 83.33333333%; }
	.offset-md-11 { margin-left: 91.66666667%; }

	.g-md-0,
	.gx-md-0 { --bs-gutter-x: 0; }

	.g-md-0,
	.gy-md-0 { --bs-gutter-y: 0; }

	.g-md-1,
	.gx-md-1 { --bs-gutter-x: 0.25rem; }

	.g-md-1,
	.gy-md-1 { --bs-gutter-y: 0.25rem; }

	.g-md-2,
	.gx-md-2 { --bs-gutter-x: 0.5rem; }

	.g-md-2,
	.gy-md-2 { --bs-gutter-y: 0.5rem; }

	.g-md-3,
	.gx-md-3 { --bs-gutter-x: 1rem; }

	.g-md-3,
	.gy-md-3 { --bs-gutter-y: 1rem; }

	.g-md-4,
	.gx-md-4 { --bs-gutter-x: 1.5rem; }

	.g-md-4,
	.gy-md-4 { --bs-gutter-y: 1.5rem; }

	.g-md-5,
	.gx-md-5 { --bs-gutter-x: 3rem; }

	.g-md-5,
	.gy-md-5 { --bs-gutter-y: 3rem; }
}

@media (min-width: 992px) {
	.col-lg-auto {
		flex: 0 0 auto;

		width: auto;
	}

	.col-lg-1 {
		flex: 0 0 auto;

		width: 8.33333333%;
	}

	.col-lg-2 {
		flex: 0 0 auto;

		width: 16.66666667%;
	}

	.col-lg-3 {
		flex: 0 0 auto;

		width: 25%;
	}

	.col-lg-4 {
		flex: 0 0 auto;

		width: 33.33333333%;
	}

	.col-lg-5 {
		flex: 0 0 auto;

		width: 41.66666667%;
	}

	.col-lg-6 {
		flex: 0 0 auto;

		width: 50%;
	}

	.col-lg-7 {
		flex: 0 0 auto;

		width: 58.33333333%;
	}

	.col-lg-8 {
		flex: 0 0 auto;

		width: 66.66666667%;
	}

	.col-lg-9 {
		flex: 0 0 auto;

		width: 75%;
	}

	.col-lg-10 {
		flex: 0 0 auto;

		width: 83.33333333%;
	}

	.col-lg-11 {
		flex: 0 0 auto;

		width: 91.66666667%;
	}

	.col-lg-12 {
		flex: 0 0 auto;

		width: 100%;
	}
	.offset-lg-0 { margin-left: 0; }
	.offset-lg-1 { margin-left: 8.33333333%; }
	.offset-lg-2 { margin-left: 16.66666667%; }
	.offset-lg-3 { margin-left: 25%; }
	.offset-lg-4 { margin-left: 33.33333333%; }
	.offset-lg-5 { margin-left: 41.66666667%; }
	.offset-lg-6 { margin-left: 50%; }
	.offset-lg-7 { margin-left: 58.33333333%; }
	.offset-lg-8 { margin-left: 66.66666667%; }
	.offset-lg-9 { margin-left: 75%; }
	.offset-lg-10 { margin-left: 83.33333333%; }
	.offset-lg-11 { margin-left: 91.66666667%; }

	.g-lg-0,
	.gx-lg-0 { --bs-gutter-x: 0; }

	.g-lg-0,
	.gy-lg-0 { --bs-gutter-y: 0; }

	.g-lg-1,
	.gx-lg-1 { --bs-gutter-x: 0.25rem; }

	.g-lg-1,
	.gy-lg-1 { --bs-gutter-y: 0.25rem; }

	.g-lg-2,
	.gx-lg-2 { --bs-gutter-x: 0.5rem; }

	.g-lg-2,
	.gy-lg-2 { --bs-gutter-y: 0.5rem; }

	.g-lg-3,
	.gx-lg-3 { --bs-gutter-x: 1rem; }

	.g-lg-3,
	.gy-lg-3 { --bs-gutter-y: 1rem; }

	.g-lg-4,
	.gx-lg-4 { --bs-gutter-x: 1.5rem; }

	.g-lg-4,
	.gy-lg-4 { --bs-gutter-y: 1.5rem; }

	.g-lg-5,
	.gx-lg-5 { --bs-gutter-x: 3rem; }

	.g-lg-5,
	.gy-lg-5 { --bs-gutter-y: 3rem; }
}

@media (min-width: 1200px) {
	.col-xl-auto {
		flex: 0 0 auto;

		width: auto;
	}

	.col-xl-1 {
		flex: 0 0 auto;

		width: 8.33333333%;
	}

	.col-xl-2 {
		flex: 0 0 auto;

		width: 16.66666667%;
	}

	.col-xl-3 {
		flex: 0 0 auto;

		width: 25%;
	}

	.col-xl-4 {
		flex: 0 0 auto;

		width: 33.33333333%;
	}

	.col-xl-5 {
		flex: 0 0 auto;

		width: 41.66666667%;
	}

	.col-xl-6 {
		flex: 0 0 auto;

		width: 50%;
	}

	.col-xl-7 {
		flex: 0 0 auto;

		width: 58.33333333%;
	}

	.col-xl-8 {
		flex: 0 0 auto;

		width: 66.66666667%;
	}

	.col-xl-9 {
		flex: 0 0 auto;

		width: 75%;
	}

	.col-xl-10 {
		flex: 0 0 auto;

		width: 83.33333333%;
	}

	.col-xl-11 {
		flex: 0 0 auto;

		width: 91.66666667%;
	}

	.col-xl-12 {
		flex: 0 0 auto;

		width: 100%;
	}
	.offset-xl-0 { margin-left: 0; }
	.offset-xl-1 { margin-left: 8.33333333%; }
	.offset-xl-2 { margin-left: 16.66666667%; }
	.offset-xl-3 { margin-left: 25%; }
	.offset-xl-4 { margin-left: 33.33333333%; }
	.offset-xl-5 { margin-left: 41.66666667%; }
	.offset-xl-6 { margin-left: 50%; }
	.offset-xl-7 { margin-left: 58.33333333%; }
	.offset-xl-8 { margin-left: 66.66666667%; }
	.offset-xl-9 { margin-left: 75%; }
	.offset-xl-10 { margin-left: 83.33333333%; }
	.offset-xl-11 { margin-left: 91.66666667%; }

	.g-xl-0,
	.gx-xl-0 { --bs-gutter-x: 0; }

	.g-xl-0,
	.gy-xl-0 { --bs-gutter-y: 0; }

	.g-xl-1,
	.gx-xl-1 { --bs-gutter-x: 0.25rem; }

	.g-xl-1,
	.gy-xl-1 { --bs-gutter-y: 0.25rem; }

	.g-xl-2,
	.gx-xl-2 { --bs-gutter-x: 0.5rem; }

	.g-xl-2,
	.gy-xl-2 { --bs-gutter-y: 0.5rem; }

	.g-xl-3,
	.gx-xl-3 { --bs-gutter-x: 1rem; }

	.g-xl-3,
	.gy-xl-3 { --bs-gutter-y: 1rem; }

	.g-xl-4,
	.gx-xl-4 { --bs-gutter-x: 1.5rem; }

	.g-xl-4,
	.gy-xl-4 { --bs-gutter-y: 1.5rem; }

	.g-xl-5,
	.gx-xl-5 { --bs-gutter-x: 3rem; }

	.g-xl-5,
	.gy-xl-5 { --bs-gutter-y: 3rem; }
}

@media (min-width: 1400px) {
	.col-xxl-auto {
		flex: 0 0 auto;

		width: auto;
	}

	.col-xxl-1 {
		flex: 0 0 auto;

		width: 8.33333333%;
	}

	.col-xxl-2 {
		flex: 0 0 auto;

		width: 16.66666667%;
	}

	.col-xxl-3 {
		flex: 0 0 auto;

		width: 25%;
	}

	.col-xxl-4 {
		flex: 0 0 auto;

		width: 33.33333333%;
	}

	.col-xxl-5 {
		flex: 0 0 auto;

		width: 41.66666667%;
	}

	.col-xxl-6 {
		flex: 0 0 auto;

		width: 50%;
	}

	.col-xxl-7 {
		flex: 0 0 auto;

		width: 58.33333333%;
	}

	.col-xxl-8 {
		flex: 0 0 auto;

		width: 66.66666667%;
	}

	.col-xxl-9 {
		flex: 0 0 auto;

		width: 75%;
	}

	.col-xxl-10 {
		flex: 0 0 auto;

		width: 83.33333333%;
	}

	.col-xxl-11 {
		flex: 0 0 auto;

		width: 91.66666667%;
	}

	.col-xxl-12 {
		flex: 0 0 auto;

		width: 100%;
	}
	.offset-xxl-0 { margin-left: 0; }
	.offset-xxl-1 { margin-left: 8.33333333%; }
	.offset-xxl-2 { margin-left: 16.66666667%; }
	.offset-xxl-3 { margin-left: 25%; }
	.offset-xxl-4 { margin-left: 33.33333333%; }
	.offset-xxl-5 { margin-left: 41.66666667%; }
	.offset-xxl-6 { margin-left: 50%; }
	.offset-xxl-7 { margin-left: 58.33333333%; }
	.offset-xxl-8 { margin-left: 66.66666667%; }
	.offset-xxl-9 { margin-left: 75%; }
	.offset-xxl-10 { margin-left: 83.33333333%; }
	.offset-xxl-11 { margin-left: 91.66666667%; }

	.g-xxl-0,
	.gx-xxl-0 { --bs-gutter-x: 0; }

	.g-xxl-0,
	.gy-xxl-0 { --bs-gutter-y: 0; }

	.g-xxl-1,
	.gx-xxl-1 { --bs-gutter-x: 0.25rem; }

	.g-xxl-1,
	.gy-xxl-1 { --bs-gutter-y: 0.25rem; }

	.g-xxl-2,
	.gx-xxl-2 { --bs-gutter-x: 0.5rem; }

	.g-xxl-2,
	.gy-xxl-2 { --bs-gutter-y: 0.5rem; }

	.g-xxl-3,
	.gx-xxl-3 { --bs-gutter-x: 1rem; }

	.g-xxl-3,
	.gy-xxl-3 { --bs-gutter-y: 1rem; }

	.g-xxl-4,
	.gx-xxl-4 { --bs-gutter-x: 1.5rem; }

	.g-xxl-4,
	.gy-xxl-4 { --bs-gutter-y: 1.5rem; }

	.g-xxl-5,
	.gx-xxl-5 { --bs-gutter-x: 3rem; }

	.g-xxl-5,
	.gy-xxl-5 { --bs-gutter-y: 3rem; }
}
.d-inline { display: inline !important; }
.d-inline-block { display: inline-block !important; }
.d-block { display: block !important; }
.d-grid { display: grid !important; }
.d-table { display: table !important; }
.d-table-row { display: table-row !important; }
.d-table-cell { display: table-cell !important; }
.d-flex { display: flex !important; }
.d-inline-flex { display: inline-flex !important; }
.d-none { display: none !important; }
.flex-fill { flex: 1 1 auto !important; }
.flex-row { flex-direction: row !important; }
.flex-column { flex-direction: column !important; }
.flex-row-reverse { flex-direction: row-reverse !important; }
.flex-column-reverse { flex-direction: column-reverse !important; }
.flex-grow-0 { flex-grow: 0 !important; }
.flex-grow-1 { flex-grow: 1 !important; }
.flex-shrink-0 { flex-shrink: 0 !important; }
.flex-shrink-1 { flex-shrink: 1 !important; }
.flex-wrap { flex-wrap: wrap !important; }
.flex-nowrap { flex-wrap: nowrap !important; }
.flex-wrap-reverse { flex-wrap: wrap-reverse !important; }
.justify-content-start { justify-content: flex-start !important; }
.justify-content-end { justify-content: flex-end !important; }
.justify-content-center { justify-content: center !important; }
.justify-content-between { justify-content: space-between !important; }
.justify-content-around { justify-content: space-around !important; }
.justify-content-evenly { justify-content: space-evenly !important; }
.align-items-start { align-items: flex-start !important; }
.align-items-end { align-items: flex-end !important; }
.align-items-center { align-items: center !important; }
.align-items-baseline { align-items: baseline !important; }
.align-items-stretch { align-items: stretch !important; }
.align-content-start { align-content: flex-start !important; }
.align-content-end { align-content: flex-end !important; }
.align-content-center { align-content: center !important; }
.align-content-between { align-content: space-between !important; }
.align-content-around { align-content: space-around !important; }
.align-content-stretch { align-content: stretch !important; }
.align-self-auto { align-self: auto !important; }
.align-self-start { align-self: flex-start !important; }
.align-self-end { align-self: flex-end !important; }
.align-self-center { align-self: center !important; }
.align-self-baseline { align-self: baseline !important; }
.align-self-stretch { align-self: stretch !important; }
.order-first { order: -1 !important; }
.order-0 { order: 0 !important; }
.order-1 { order: 1 !important; }
.order-2 { order: 2 !important; }
.order-3 { order: 3 !important; }
.order-4 { order: 4 !important; }
.order-5 { order: 5 !important; }
.order-last { order: 6 !important; }
.m-0 { margin: 0 !important; }
.m-1 { margin: 0.25rem !important; }
.m-2 { margin: 0.5rem !important; }
.m-3 { margin: 1rem !important; }
.m-4 { margin: 1.5rem !important; }
.m-5 { margin: 3rem !important; }
.m-auto { margin: auto !important; }
.mx-0 { margin-right: 0 !important; margin-left: 0 !important; }
.mx-1 { margin-right: 0.25rem !important; margin-left: 0.25rem !important; }
.mx-2 { margin-right: 0.5rem !important; margin-left: 0.5rem !important; }
.mx-3 { margin-right: 1rem !important; margin-left: 1rem !important; }
.mx-4 { margin-right: 1.5rem !important; margin-left: 1.5rem !important; }
.mx-5 { margin-right: 3rem !important; margin-left: 3rem !important; }
.mx-auto { margin-right: auto !important; margin-left: auto !important; }
.my-0 { margin-top: 0 !important; margin-bottom: 0 !important; }
.my-1 { margin-top: 0.25rem !important; margin-bottom: 0.25rem !important; }
.my-2 { margin-top: 0.5rem !important; margin-bottom: 0.5rem !important; }
.my-3 { margin-top: 1rem !important; margin-bottom: 1rem !important; }
.my-4 { margin-top: 1.5rem !important; margin-bottom: 1.5rem !important; }
.my-5 { margin-top: 3rem !important; margin-bottom: 3rem !important; }
.my-auto { margin-top: auto !important; margin-bottom: auto !important; }
.mt-0 { margin-top: 0 !important; }
.mt-1 { margin-top: 0.25rem !important; }
.mt-2 { margin-top: 0.5rem !important; }
.mt-3 { margin-top: 1rem !important; }
.mt-4 { margin-top: 1.5rem !important; }
.mt-5 { margin-top: 3rem !important; }
.mt-auto { margin-top: auto !important; }
.me-0 { margin-right: 0 !important; }
.me-1 { margin-right: 0.25rem !important; }
.me-2 { margin-right: 0.5rem !important; }
.me-3 { margin-right: 1rem !important; }
.me-4 { margin-right: 1.5rem !important; }
.me-5 { margin-right: 3rem !important; }
.me-auto { margin-right: auto !important; }
.mb-0 { margin-bottom: 0 !important; }
.mb-1 { margin-bottom: 0.25rem !important; }
.mb-2 { margin-bottom: 0.5rem !important; }
.mb-3 { margin-bottom: 1rem !important; }
.mb-4 { margin-bottom: 1.5rem !important; }
.mb-5 { margin-bottom: 3rem !important; }
.mb-auto { margin-bottom: auto !important; }
.ms-0 { margin-left: 0 !important; }
.ms-1 { margin-left: 0.25rem !important; }
.ms-2 { margin-left: 0.5rem !important; }
.ms-3 { margin-left: 1rem !important; }
.ms-4 { margin-left: 1.5rem !important; }
.ms-5 { margin-left: 3rem !important; }
.ms-auto { margin-left: auto !important; }
.p-0 { padding: 0 !important; }
.p-1 { padding: 0.25rem !important; }
.p-2 { padding: 0.5rem !important; }
.p-3 { padding: 1rem !important; }
.p-4 { padding: 1.5rem !important; }
.p-5 { padding: 3rem !important; }
.px-0 { padding-right: 0 !important; padding-left: 0 !important; }
.px-1 { padding-right: 0.25rem !important; padding-left: 0.25rem !important; }
.px-2 { padding-right: 0.5rem !important; padding-left: 0.5rem !important; }
.px-3 { padding-right: 1rem !important; padding-left: 1rem !important; }
.px-4 { padding-right: 1.5rem !important; padding-left: 1.5rem !important; }
.px-5 { padding-right: 3rem !important; padding-left: 3rem !important; }
.py-0 { padding-top: 0 !important; padding-bottom: 0 !important; }
.py-1 { padding-top: 0.25rem !important; padding-bottom: 0.25rem !important; }
.py-2 { padding-top: 0.5rem !important; padding-bottom: 0.5rem !important; }
.py-3 { padding-top: 1rem !important; padding-bottom: 1rem !important; }
.py-4 { padding-top: 1.5rem !important; padding-bottom: 1.5rem !important; }
.py-5 { padding-top: 3rem !important; padding-bottom: 3rem !important; }
.pt-0 { padding-top: 0 !important; }
.pt-1 { padding-top: 0.25rem !important; }
.pt-2 { padding-top: 0.5rem !important; }
.pt-3 { padding-top: 1rem !important; }
.pt-4 { padding-top: 1.5rem !important; }
.pt-5 { padding-top: 3rem !important; }
.pe-0 { padding-right: 0 !important; }
.pe-1 { padding-right: 0.25rem !important; }
.pe-2 { padding-right: 0.5rem !important; }
.pe-3 { padding-right: 1rem !important; }
.pe-4 { padding-right: 1.5rem !important; }
.pe-5 { padding-right: 3rem !important; }
.pb-0 { padding-bottom: 0 !important; }
.pb-1 { padding-bottom: 0.25rem !important; }
.pb-2 { padding-bottom: 0.5rem !important; }
.pb-3 { padding-bottom: 1rem !important; }
.pb-4 { padding-bottom: 1.5rem !important; }
.pb-5 { padding-bottom: 3rem !important; }
.ps-0 { padding-left: 0 !important; }
.ps-1 { padding-left: 0.25rem !important; }
.ps-2 { padding-left: 0.5rem !important; }
.ps-3 { padding-left: 1rem !important; }
.ps-4 { padding-left: 1.5rem !important; }
.ps-5 { padding-left: 3rem !important; }

@media (min-width: 576px) {
	.d-sm-inline { display: inline !important; }
	.d-sm-inline-block { display: inline-block !important; }
	.d-sm-block { display: block !important; }
	.d-sm-grid { display: grid !important; }
	.d-sm-table { display: table !important; }
	.d-sm-table-row { display: table-row !important; }
	.d-sm-table-cell { display: table-cell !important; }
	.d-sm-flex { display: flex !important; }
	.d-sm-inline-flex { display: inline-flex !important; }
	.d-sm-none { display: none !important; }
	.flex-sm-fill { flex: 1 1 auto !important; }
	.flex-sm-row { flex-direction: row !important; }
	.flex-sm-column { flex-direction: column !important; }
	.flex-sm-row-reverse { flex-direction: row-reverse !important; }
	.flex-sm-column-reverse { flex-direction: column-reverse !important; }
	.flex-sm-grow-0 { flex-grow: 0 !important; }
	.flex-sm-grow-1 { flex-grow: 1 !important; }
	.flex-sm-shrink-0 { flex-shrink: 0 !important; }
	.flex-sm-shrink-1 { flex-shrink: 1 !important; }
	.flex-sm-wrap { flex-wrap: wrap !important; }
	.flex-sm-nowrap { flex-wrap: nowrap !important; }
	.flex-sm-wrap-reverse { flex-wrap: wrap-reverse !important; }
	.justify-content-sm-start { justify-content: flex-start !important; }
	.justify-content-sm-end { justify-content: flex-end !important; }
	.justify-content-sm-center { justify-content: center !important; }
	.justify-content-sm-between { justify-content: space-between !important; }
	.justify-content-sm-around { justify-content: space-around !important; }
	.justify-content-sm-evenly { justify-content: space-evenly !important; }
	.align-items-sm-start { align-items: flex-start !important; }
	.align-items-sm-end { align-items: flex-end !important; }
	.align-items-sm-center { align-items: center !important; }
	.align-items-sm-baseline { align-items: baseline !important; }
	.align-items-sm-stretch { align-items: stretch !important; }
	.align-content-sm-start { align-content: flex-start !important; }
	.align-content-sm-end { align-content: flex-end !important; }
	.align-content-sm-center { align-content: center !important; }
	.align-content-sm-between { align-content: space-between !important; }
	.align-content-sm-around { align-content: space-around !important; }
	.align-content-sm-stretch { align-content: stretch !important; }
	.align-self-sm-auto { align-self: auto !important; }
	.align-self-sm-start { align-self: flex-start !important; }
	.align-self-sm-end { align-self: flex-end !important; }
	.align-self-sm-center { align-self: center !important; }
	.align-self-sm-baseline { align-self: baseline !important; }
	.align-self-sm-stretch { align-self: stretch !important; }
	.order-sm-first { order: -1 !important; }
	.order-sm-0 { order: 0 !important; }
	.order-sm-1 { order: 1 !important; }
	.order-sm-2 { order: 2 !important; }
	.order-sm-3 { order: 3 !important; }
	.order-sm-4 { order: 4 !important; }
	.order-sm-5 { order: 5 !important; }
	.order-sm-last { order: 6 !important; }
	.m-sm-0 { margin: 0 !important; }
	.m-sm-1 { margin: 0.25rem !important; }
	.m-sm-2 { margin: 0.5rem !important; }
	.m-sm-3 { margin: 1rem !important; }
	.m-sm-4 { margin: 1.5rem !important; }
	.m-sm-5 { margin: 3rem !important; }
	.m-sm-auto { margin: auto !important; }
	.mx-sm-0 { margin-right: 0 !important; margin-left: 0 !important; }
	.mx-sm-1 { margin-right: 0.25rem !important; margin-left: 0.25rem !important; }
	.mx-sm-2 { margin-right: 0.5rem !important; margin-left: 0.5rem !important; }
	.mx-sm-3 { margin-right: 1rem !important; margin-left: 1rem !important; }
	.mx-sm-4 { margin-right: 1.5rem !important; margin-left: 1.5rem !important; }
	.mx-sm-5 { margin-right: 3rem !important; margin-left: 3rem !important; }
	.mx-sm-auto { margin-right: auto !important; margin-left: auto !important; }
	.my-sm-0 { margin-top: 0 !important; margin-bottom: 0 !important; }
	.my-sm-1 { margin-top: 0.25rem !important; margin-bottom: 0.25rem !important; }
	.my-sm-2 { margin-top: 0.5rem !important; margin-bottom: 0.5rem !important; }
	.my-sm-3 { margin-top: 1rem !important; margin-bottom: 1rem !important; }
	.my-sm-4 { margin-top: 1.5rem !important; margin-bottom: 1.5rem !important; }
	.my-sm-5 { margin-top: 3rem !important; margin-bottom: 3rem !important; }
	.my-sm-auto { margin-top: auto !important; margin-bottom: auto !important; }
	.mt-sm-0 { margin-top: 0 !important; }
	.mt-sm-1 { margin-top: 0.25rem !important; }
	.mt-sm-2 { margin-top: 0.5rem !important; }
	.mt-sm-3 { margin-top: 1rem !important; }
	.mt-sm-4 { margin-top: 1.5rem !important; }
	.mt-sm-5 { margin-top: 3rem !important; }
	.mt-sm-auto { margin-top: auto !important; }
	.me-sm-0 { margin-right: 0 !important; }
	.me-sm-1 { margin-right: 0.25rem !important; }
	.me-sm-2 { margin-right: 0.5rem !important; }
	.me-sm-3 { margin-right: 1rem !important; }
	.me-sm-4 { margin-right: 1.5rem !important; }
	.me-sm-5 { margin-right: 3rem !important; }
	.me-sm-auto { margin-right: auto !important; }
	.mb-sm-0 { margin-bottom: 0 !important; }
	.mb-sm-1 { margin-bottom: 0.25rem !important; }
	.mb-sm-2 { margin-bottom: 0.5rem !important; }
	.mb-sm-3 { margin-bottom: 1rem !important; }
	.mb-sm-4 { margin-bottom: 1.5rem !important; }
	.mb-sm-5 { margin-bottom: 3rem !important; }
	.mb-sm-auto { margin-bottom: auto !important; }
	.ms-sm-0 { margin-left: 0 !important; }
	.ms-sm-1 { margin-left: 0.25rem !important; }
	.ms-sm-2 { margin-left: 0.5rem !important; }
	.ms-sm-3 { margin-left: 1rem !important; }
	.ms-sm-4 { margin-left: 1.5rem !important; }
	.ms-sm-5 { margin-left: 3rem !important; }
	.ms-sm-auto { margin-left: auto !important; }
	.p-sm-0 { padding: 0 !important; }
	.p-sm-1 { padding: 0.25rem !important; }
	.p-sm-2 { padding: 0.5rem !important; }
	.p-sm-3 { padding: 1rem !important; }
	.p-sm-4 { padding: 1.5rem !important; }
	.p-sm-5 { padding: 3rem !important; }
	.px-sm-0 { padding-right: 0 !important; padding-left: 0 !important; }
	.px-sm-1 { padding-right: 0.25rem !important; padding-left: 0.25rem !important; }
	.px-sm-2 { padding-right: 0.5rem !important; padding-left: 0.5rem !important; }
	.px-sm-3 { padding-right: 1rem !important; padding-left: 1rem !important; }
	.px-sm-4 { padding-right: 1.5rem !important; padding-left: 1.5rem !important; }
	.px-sm-5 { padding-right: 3rem !important; padding-left: 3rem !important; }
	.py-sm-0 { padding-top: 0 !important; padding-bottom: 0 !important; }
	.py-sm-1 { padding-top: 0.25rem !important; padding-bottom: 0.25rem !important; }
	.py-sm-2 { padding-top: 0.5rem !important; padding-bottom: 0.5rem !important; }
	.py-sm-3 { padding-top: 1rem !important; padding-bottom: 1rem !important; }
	.py-sm-4 { padding-top: 1.5rem !important; padding-bottom: 1.5rem !important; }
	.py-sm-5 { padding-top: 3rem !important; padding-bottom: 3rem !important; }
	.pt-sm-0 { padding-top: 0 !important; }
	.pt-sm-1 { padding-top: 0.25rem !important; }
	.pt-sm-2 { padding-top: 0.5rem !important; }
	.pt-sm-3 { padding-top: 1rem !important; }
	.pt-sm-4 { padding-top: 1.5rem !important; }
	.pt-sm-5 { padding-top: 3rem !important; }
	.pe-sm-0 { padding-right: 0 !important; }
	.pe-sm-1 { padding-right: 0.25rem !important; }
	.pe-sm-2 { padding-right: 0.5rem !important; }
	.pe-sm-3 { padding-right: 1rem !important; }
	.pe-sm-4 { padding-right: 1.5rem !important; }
	.pe-sm-5 { padding-right: 3rem !important; }
	.pb-sm-0 { padding-bottom: 0 !important; }
	.pb-sm-1 { padding-bottom: 0.25rem !important; }
	.pb-sm-2 { padding-bottom: 0.5rem !important; }
	.pb-sm-3 { padding-bottom: 1rem !important; }
	.pb-sm-4 { padding-bottom: 1.5rem !important; }
	.pb-sm-5 { padding-bottom: 3rem !important; }
	.ps-sm-0 { padding-left: 0 !important; }
	.ps-sm-1 { padding-left: 0.25rem !important; }
	.ps-sm-2 { padding-left: 0.5rem !important; }
	.ps-sm-3 { padding-left: 1rem !important; }
	.ps-sm-4 { padding-left: 1.5rem !important; }
	.ps-sm-5 { padding-left: 3rem !important; }
}

@media (min-width: 768px) {
	.d-md-inline { display: inline !important; }
	.d-md-inline-block { display: inline-block !important; }
	.d-md-block { display: block !important; }
	.d-md-grid { display: grid !important; }
	.d-md-table { display: table !important; }
	.d-md-table-row { display: table-row !important; }
	.d-md-table-cell { display: table-cell !important; }
	.d-md-flex { display: flex !important; }
	.d-md-inline-flex { display: inline-flex !important; }
	.d-md-none { display: none !important; }
	.flex-md-fill { flex: 1 1 auto !important; }
	.flex-md-row { flex-direction: row !important; }
	.flex-md-column { flex-direction: column !important; }
	.flex-md-row-reverse { flex-direction: row-reverse !important; }
	.flex-md-column-reverse { flex-direction: column-reverse !important; }
	.flex-md-grow-0 { flex-grow: 0 !important; }
	.flex-md-grow-1 { flex-grow: 1 !important; }
	.flex-md-shrink-0 { flex-shrink: 0 !important; }
	.flex-md-shrink-1 { flex-shrink: 1 !important; }
	.flex-md-wrap { flex-wrap: wrap !important; }
	.flex-md-nowrap { flex-wrap: nowrap !important; }
	.flex-md-wrap-reverse { flex-wrap: wrap-reverse !important; }
	.justify-content-md-start { justify-content: flex-start !important; }
	.justify-content-md-end { justify-content: flex-end !important; }
	.justify-content-md-center { justify-content: center !important; }
	.justify-content-md-between { justify-content: space-between !important; }
	.justify-content-md-around { justify-content: space-around !important; }
	.justify-content-md-evenly { justify-content: space-evenly !important; }
	.align-items-md-start { align-items: flex-start !important; }
	.align-items-md-end { align-items: flex-end !important; }
	.align-items-md-center { align-items: center !important; }
	.align-items-md-baseline { align-items: baseline !important; }
	.align-items-md-stretch { align-items: stretch !important; }
	.align-content-md-start { align-content: flex-start !important; }
	.align-content-md-end { align-content: flex-end !important; }
	.align-content-md-center { align-content: center !important; }
	.align-content-md-between { align-content: space-between !important; }
	.align-content-md-around { align-content: space-around !important; }
	.align-content-md-stretch { align-content: stretch !important; }
	.align-self-md-auto { align-self: auto !important; }
	.align-self-md-start { align-self: flex-start !important; }
	.align-self-md-end { align-self: flex-end !important; }
	.align-self-md-center { align-self: center !important; }
	.align-self-md-baseline { align-self: baseline !important; }
	.align-self-md-stretch { align-self: stretch !important; }
	.order-md-first { order: -1 !important; }
	.order-md-0 { order: 0 !important; }
	.order-md-1 { order: 1 !important; }
	.order-md-2 { order: 2 !important; }
	.order-md-3 { order: 3 !important; }
	.order-md-4 { order: 4 !important; }
	.order-md-5 { order: 5 !important; }
	.order-md-last { order: 6 !important; }
	.m-md-0 { margin: 0 !important; }
	.m-md-1 { margin: 0.25rem !important; }
	.m-md-2 { margin: 0.5rem !important; }
	.m-md-3 { margin: 1rem !important; }
	.m-md-4 { margin: 1.5rem !important; }
	.m-md-5 { margin: 3rem !important; }
	.m-md-auto { margin: auto !important; }
	.mx-md-0 { margin-right: 0 !important; margin-left: 0 !important; }
	.mx-md-1 { margin-right: 0.25rem !important; margin-left: 0.25rem !important; }
	.mx-md-2 { margin-right: 0.5rem !important; margin-left: 0.5rem !important; }
	.mx-md-3 { margin-right: 1rem !important; margin-left: 1rem !important; }
	.mx-md-4 { margin-right: 1.5rem !important; margin-left: 1.5rem !important; }
	.mx-md-5 { margin-right: 3rem !important; margin-left: 3rem !important; }
	.mx-md-auto { margin-right: auto !important; margin-left: auto !important; }
	.my-md-0 { margin-top: 0 !important; margin-bottom: 0 !important; }
	.my-md-1 { margin-top: 0.25rem !important; margin-bottom: 0.25rem !important; }
	.my-md-2 { margin-top: 0.5rem !important; margin-bottom: 0.5rem !important; }
	.my-md-3 { margin-top: 1rem !important; margin-bottom: 1rem !important; }
	.my-md-4 { margin-top: 1.5rem !important; margin-bottom: 1.5rem !important; }
	.my-md-5 { margin-top: 3rem !important; margin-bottom: 3rem !important; }
	.my-md-auto { margin-top: auto !important; margin-bottom: auto !important; }
	.mt-md-0 { margin-top: 0 !important; }
	.mt-md-1 { margin-top: 0.25rem !important; }
	.mt-md-2 { margin-top: 0.5rem !important; }
	.mt-md-3 { margin-top: 1rem !important; }
	.mt-md-4 { margin-top: 1.5rem !important; }
	.mt-md-5 { margin-top: 3rem !important; }
	.mt-md-auto { margin-top: auto !important; }
	.me-md-0 { margin-right: 0 !important; }
	.me-md-1 { margin-right: 0.25rem !important; }
	.me-md-2 { margin-right: 0.5rem !important; }
	.me-md-3 { margin-right: 1rem !important; }
	.me-md-4 { margin-right: 1.5rem !important; }
	.me-md-5 { margin-right: 3rem !important; }
	.me-md-auto { margin-right: auto !important; }
	.mb-md-0 { margin-bottom: 0 !important; }
	.mb-md-1 { margin-bottom: 0.25rem !important; }
	.mb-md-2 { margin-bottom: 0.5rem !important; }
	.mb-md-3 { margin-bottom: 1rem !important; }
	.mb-md-4 { margin-bottom: 1.5rem !important; }
	.mb-md-5 { margin-bottom: 3rem !important; }
	.mb-md-auto { margin-bottom: auto !important; }
	.ms-md-0 { margin-left: 0 !important; }
	.ms-md-1 { margin-left: 0.25rem !important; }
	.ms-md-2 { margin-left: 0.5rem !important; }
	.ms-md-3 { margin-left: 1rem !important; }
	.ms-md-4 { margin-left: 1.5rem !important; }
	.ms-md-5 { margin-left: 3rem !important; }
	.ms-md-auto { margin-left: auto !important; }
	.p-md-0 { padding: 0 !important; }
	.p-md-1 { padding: 0.25rem !important; }
	.p-md-2 { padding: 0.5rem !important; }
	.p-md-3 { padding: 1rem !important; }
	.p-md-4 { padding: 1.5rem !important; }
	.p-md-5 { padding: 3rem !important; }
	.px-md-0 { padding-right: 0 !important; padding-left: 0 !important; }
	.px-md-1 { padding-right: 0.25rem !important; padding-left: 0.25rem !important; }
	.px-md-2 { padding-right: 0.5rem !important; padding-left: 0.5rem !important; }
	.px-md-3 { padding-right: 1rem !important; padding-left: 1rem !important; }
	.px-md-4 { padding-right: 1.5rem !important; padding-left: 1.5rem !important; }
	.px-md-5 { padding-right: 3rem !important; padding-left: 3rem !important; }
	.py-md-0 { padding-top: 0 !important; padding-bottom: 0 !important; }
	.py-md-1 { padding-top: 0.25rem !important; padding-bottom: 0.25rem !important; }
	.py-md-2 { padding-top: 0.5rem !important; padding-bottom: 0.5rem !important; }
	.py-md-3 { padding-top: 1rem !important; padding-bottom: 1rem !important; }
	.py-md-4 { padding-top: 1.5rem !important; padding-bottom: 1.5rem !important; }
	.py-md-5 { padding-top: 3rem !important; padding-bottom: 3rem !important; }
	.pt-md-0 { padding-top: 0 !important; }
	.pt-md-1 { padding-top: 0.25rem !important; }
	.pt-md-2 { padding-top: 0.5rem !important; }
	.pt-md-3 { padding-top: 1rem !important; }
	.pt-md-4 { padding-top: 1.5rem !important; }
	.pt-md-5 { padding-top: 3rem !important; }
	.pe-md-0 { padding-right: 0 !important; }
	.pe-md-1 { padding-right: 0.25rem !important; }
	.pe-md-2 { padding-right: 0.5rem !important; }
	.pe-md-3 { padding-right: 1rem !important; }
	.pe-md-4 { padding-right: 1.5rem !important; }
	.pe-md-5 { padding-right: 3rem !important; }
	.pb-md-0 { padding-bottom: 0 !important; }
	.pb-md-1 { padding-bottom: 0.25rem !important; }
	.pb-md-2 { padding-bottom: 0.5rem !important; }
	.pb-md-3 { padding-bottom: 1rem !important; }
	.pb-md-4 { padding-bottom: 1.5rem !important; }
	.pb-md-5 { padding-bottom: 3rem !important; }
	.ps-md-0 { padding-left: 0 !important; }
	.ps-md-1 { padding-left: 0.25rem !important; }
	.ps-md-2 { padding-left: 0.5rem !important; }
	.ps-md-3 { padding-left: 1rem !important; }
	.ps-md-4 { padding-left: 1.5rem !important; }
	.ps-md-5 { padding-left: 3rem !important; }
}

@media (min-width: 992px) {
	.d-lg-inline { display: inline !important; }
	.d-lg-inline-block { display: inline-block !important; }
	.d-lg-block { display: block !important; }
	.d-lg-grid { display: grid !important; }
	.d-lg-table { display: table !important; }
	.d-lg-table-row { display: table-row !important; }
	.d-lg-table-cell { display: table-cell !important; }
	.d-lg-flex { display: flex !important; }
	.d-lg-inline-flex { display: inline-flex !important; }
	.d-lg-none { display: none !important; }
	.flex-lg-fill { flex: 1 1 auto !important; }
	.flex-lg-row { flex-direction: row !important; }
	.flex-lg-column { flex-direction: column !important; }
	.flex-lg-row-reverse { flex-direction: row-reverse !important; }
	.flex-lg-column-reverse { flex-direction: column-reverse !important; }
	.flex-lg-grow-0 { flex-grow: 0 !important; }
	.flex-lg-grow-1 { flex-grow: 1 !important; }
	.flex-lg-shrink-0 { flex-shrink: 0 !important; }
	.flex-lg-shrink-1 { flex-shrink: 1 !important; }
	.flex-lg-wrap { flex-wrap: wrap !important; }
	.flex-lg-nowrap { flex-wrap: nowrap !important; }
	.flex-lg-wrap-reverse { flex-wrap: wrap-reverse !important; }
	.justify-content-lg-start { justify-content: flex-start !important; }
	.justify-content-lg-end { justify-content: flex-end !important; }
	.justify-content-lg-center { justify-content: center !important; }
	.justify-content-lg-between { justify-content: space-between !important; }
	.justify-content-lg-around { justify-content: space-around !important; }
	.justify-content-lg-evenly { justify-content: space-evenly !important; }
	.align-items-lg-start { align-items: flex-start !important; }
	.align-items-lg-end { align-items: flex-end !important; }
	.align-items-lg-center { align-items: center !important; }
	.align-items-lg-baseline { align-items: baseline !important; }
	.align-items-lg-stretch { align-items: stretch !important; }
	.align-content-lg-start { align-content: flex-start !important; }
	.align-content-lg-end { align-content: flex-end !important; }
	.align-content-lg-center { align-content: center !important; }
	.align-content-lg-between { align-content: space-between !important; }
	.align-content-lg-around { align-content: space-around !important; }
	.align-content-lg-stretch { align-content: stretch !important; }
	.align-self-lg-auto { align-self: auto !important; }
	.align-self-lg-start { align-self: flex-start !important; }
	.align-self-lg-end { align-self: flex-end !important; }
	.align-self-lg-center { align-self: center !important; }
	.align-self-lg-baseline { align-self: baseline !important; }
	.align-self-lg-stretch { align-self: stretch !important; }
	.order-lg-first { order: -1 !important; }
	.order-lg-0 { order: 0 !important; }
	.order-lg-1 { order: 1 !important; }
	.order-lg-2 { order: 2 !important; }
	.order-lg-3 { order: 3 !important; }
	.order-lg-4 { order: 4 !important; }
	.order-lg-5 { order: 5 !important; }
	.order-lg-last { order: 6 !important; }
	.m-lg-0 { margin: 0 !important; }
	.m-lg-1 { margin: 0.25rem !important; }
	.m-lg-2 { margin: 0.5rem !important; }
	.m-lg-3 { margin: 1rem !important; }
	.m-lg-4 { margin: 1.5rem !important; }
	.m-lg-5 { margin: 3rem !important; }
	.m-lg-auto { margin: auto !important; }
	.mx-lg-0 { margin-right: 0 !important; margin-left: 0 !important; }
	.mx-lg-1 { margin-right: 0.25rem !important; margin-left: 0.25rem !important; }
	.mx-lg-2 { margin-right: 0.5rem !important; margin-left: 0.5rem !important; }
	.mx-lg-3 { margin-right: 1rem !important; margin-left: 1rem !important; }
	.mx-lg-4 { margin-right: 1.5rem !important; margin-left: 1.5rem !important; }
	.mx-lg-5 { margin-right: 3rem !important; margin-left: 3rem !important; }
	.mx-lg-auto { margin-right: auto !important; margin-left: auto !important; }
	.my-lg-0 { margin-top: 0 !important; margin-bottom: 0 !important; }
	.my-lg-1 { margin-top: 0.25rem !important; margin-bottom: 0.25rem !important; }
	.my-lg-2 { margin-top: 0.5rem !important; margin-bottom: 0.5rem !important; }
	.my-lg-3 { margin-top: 1rem !important; margin-bottom: 1rem !important; }
	.my-lg-4 { margin-top: 1.5rem !important; margin-bottom: 1.5rem !important; }
	.my-lg-5 { margin-top: 3rem !important; margin-bottom: 3rem !important; }
	.my-lg-auto { margin-top: auto !important; margin-bottom: auto !important; }
	.mt-lg-0 { margin-top: 0 !important; }
	.mt-lg-1 { margin-top: 0.25rem !important; }
	.mt-lg-2 { margin-top: 0.5rem !important; }
	.mt-lg-3 { margin-top: 1rem !important; }
	.mt-lg-4 { margin-top: 1.5rem !important; }
	.mt-lg-5 { margin-top: 3rem !important; }
	.mt-lg-auto { margin-top: auto !important; }
	.me-lg-0 { margin-right: 0 !important; }
	.me-lg-1 { margin-right: 0.25rem !important; }
	.me-lg-2 { margin-right: 0.5rem !important; }
	.me-lg-3 { margin-right: 1rem !important; }
	.me-lg-4 { margin-right: 1.5rem !important; }
	.me-lg-5 { margin-right: 3rem !important; }
	.me-lg-auto { margin-right: auto !important; }
	.mb-lg-0 { margin-bottom: 0 !important; }
	.mb-lg-1 { margin-bottom: 0.25rem !important; }
	.mb-lg-2 { margin-bottom: 0.5rem !important; }
	.mb-lg-3 { margin-bottom: 1rem !important; }
	.mb-lg-4 { margin-bottom: 1.5rem !important; }
	.mb-lg-5 { margin-bottom: 3rem !important; }
	.mb-lg-auto { margin-bottom: auto !important; }
	.ms-lg-0 { margin-left: 0 !important; }
	.ms-lg-1 { margin-left: 0.25rem !important; }
	.ms-lg-2 { margin-left: 0.5rem !important; }
	.ms-lg-3 { margin-left: 1rem !important; }
	.ms-lg-4 { margin-left: 1.5rem !important; }
	.ms-lg-5 { margin-left: 3rem !important; }
	.ms-lg-auto { margin-left: auto !important; }
	.p-lg-0 { padding: 0 !important; }
	.p-lg-1 { padding: 0.25rem !important; }
	.p-lg-2 { padding: 0.5rem !important; }
	.p-lg-3 { padding: 1rem !important; }
	.p-lg-4 { padding: 1.5rem !important; }
	.p-lg-5 { padding: 3rem !important; }
	.px-lg-0 { padding-right: 0 !important; padding-left: 0 !important; }
	.px-lg-1 { padding-right: 0.25rem !important; padding-left: 0.25rem !important; }
	.px-lg-2 { padding-right: 0.5rem !important; padding-left: 0.5rem !important; }
	.px-lg-3 { padding-right: 1rem !important; padding-left: 1rem !important; }
	.px-lg-4 { padding-right: 1.5rem !important; padding-left: 1.5rem !important; }
	.px-lg-5 { padding-right: 3rem !important; padding-left: 3rem !important; }
	.py-lg-0 { padding-top: 0 !important; padding-bottom: 0 !important; }
	.py-lg-1 { padding-top: 0.25rem !important; padding-bottom: 0.25rem !important; }
	.py-lg-2 { padding-top: 0.5rem !important; padding-bottom: 0.5rem !important; }
	.py-lg-3 { padding-top: 1rem !important; padding-bottom: 1rem !important; }
	.py-lg-4 { padding-top: 1.5rem !important; padding-bottom: 1.5rem !important; }
	.py-lg-5 { padding-top: 3rem !important; padding-bottom: 3rem !important; }
	.pt-lg-0 { padding-top: 0 !important; }
	.pt-lg-1 { padding-top: 0.25rem !important; }
	.pt-lg-2 { padding-top: 0.5rem !important; }
	.pt-lg-3 { padding-top: 1rem !important; }
	.pt-lg-4 { padding-top: 1.5rem !important; }
	.pt-lg-5 { padding-top: 3rem !important; }
	.pe-lg-0 { padding-right: 0 !important; }
	.pe-lg-1 { padding-right: 0.25rem !important; }
	.pe-lg-2 { padding-right: 0.5rem !important; }
	.pe-lg-3 { padding-right: 1rem !important; }
	.pe-lg-4 { padding-right: 1.5rem !important; }
	.pe-lg-5 { padding-right: 3rem !important; }
	.pb-lg-0 { padding-bottom: 0 !important; }
	.pb-lg-1 { padding-bottom: 0.25rem !important; }
	.pb-lg-2 { padding-bottom: 0.5rem !important; }
	.pb-lg-3 { padding-bottom: 1rem !important; }
	.pb-lg-4 { padding-bottom: 1.5rem !important; }
	.pb-lg-5 { padding-bottom: 3rem !important; }
	.ps-lg-0 { padding-left: 0 !important; }
	.ps-lg-1 { padding-left: 0.25rem !important; }
	.ps-lg-2 { padding-left: 0.5rem !important; }
	.ps-lg-3 { padding-left: 1rem !important; }
	.ps-lg-4 { padding-left: 1.5rem !important; }
	.ps-lg-5 { padding-left: 3rem !important; }
}

@media (min-width: 1200px) {
	.d-xl-inline { display: inline !important; }
	.d-xl-inline-block { display: inline-block !important; }
	.d-xl-block { display: block !important; }
	.d-xl-grid { display: grid !important; }
	.d-xl-table { display: table !important; }
	.d-xl-table-row { display: table-row !important; }
	.d-xl-table-cell { display: table-cell !important; }
	.d-xl-flex { display: flex !important; }
	.d-xl-inline-flex { display: inline-flex !important; }
	.d-xl-none { display: none !important; }
	.flex-xl-fill { flex: 1 1 auto !important; }
	.flex-xl-row { flex-direction: row !important; }
	.flex-xl-column { flex-direction: column !important; }
	.flex-xl-row-reverse { flex-direction: row-reverse !important; }
	.flex-xl-column-reverse { flex-direction: column-reverse !important; }
	.flex-xl-grow-0 { flex-grow: 0 !important; }
	.flex-xl-grow-1 { flex-grow: 1 !important; }
	.flex-xl-shrink-0 { flex-shrink: 0 !important; }
	.flex-xl-shrink-1 { flex-shrink: 1 !important; }
	.flex-xl-wrap { flex-wrap: wrap !important; }
	.flex-xl-nowrap { flex-wrap: nowrap !important; }
	.flex-xl-wrap-reverse { flex-wrap: wrap-reverse !important; }
	.justify-content-xl-start { justify-content: flex-start !important; }
	.justify-content-xl-end { justify-content: flex-end !important; }
	.justify-content-xl-center { justify-content: center !important; }
	.justify-content-xl-between { justify-content: space-between !important; }
	.justify-content-xl-around { justify-content: space-around !important; }
	.justify-content-xl-evenly { justify-content: space-evenly !important; }
	.align-items-xl-start { align-items: flex-start !important; }
	.align-items-xl-end { align-items: flex-end !important; }
	.align-items-xl-center { align-items: center !important; }
	.align-items-xl-baseline { align-items: baseline !important; }
	.align-items-xl-stretch { align-items: stretch !important; }
	.align-content-xl-start { align-content: flex-start !important; }
	.align-content-xl-end { align-content: flex-end !important; }
	.align-content-xl-center { align-content: center !important; }
	.align-content-xl-between { align-content: space-between !important; }
	.align-content-xl-around { align-content: space-around !important; }
	.align-content-xl-stretch { align-content: stretch !important; }
	.align-self-xl-auto { align-self: auto !important; }
	.align-self-xl-start { align-self: flex-start !important; }
	.align-self-xl-end { align-self: flex-end !important; }
	.align-self-xl-center { align-self: center !important; }
	.align-self-xl-baseline { align-self: baseline !important; }
	.align-self-xl-stretch { align-self: stretch !important; }
	.order-xl-first { order: -1 !important; }
	.order-xl-0 { order: 0 !important; }
	.order-xl-1 { order: 1 !important; }
	.order-xl-2 { order: 2 !important; }
	.order-xl-3 { order: 3 !important; }
	.order-xl-4 { order: 4 !important; }
	.order-xl-5 { order: 5 !important; }
	.order-xl-last { order: 6 !important; }
	.m-xl-0 { margin: 0 !important; }
	.m-xl-1 { margin: 0.25rem !important; }
	.m-xl-2 { margin: 0.5rem !important; }
	.m-xl-3 { margin: 1rem !important; }
	.m-xl-4 { margin: 1.5rem !important; }
	.m-xl-5 { margin: 3rem !important; }
	.m-xl-auto { margin: auto !important; }
	.mx-xl-0 { margin-right: 0 !important; margin-left: 0 !important; }
	.mx-xl-1 { margin-right: 0.25rem !important; margin-left: 0.25rem !important; }
	.mx-xl-2 { margin-right: 0.5rem !important; margin-left: 0.5rem !important; }
	.mx-xl-3 { margin-right: 1rem !important; margin-left: 1rem !important; }
	.mx-xl-4 { margin-right: 1.5rem !important; margin-left: 1.5rem !important; }
	.mx-xl-5 { margin-right: 3rem !important; margin-left: 3rem !important; }
	.mx-xl-auto { margin-right: auto !important; margin-left: auto !important; }
	.my-xl-0 { margin-top: 0 !important; margin-bottom: 0 !important; }
	.my-xl-1 { margin-top: 0.25rem !important; margin-bottom: 0.25rem !important; }
	.my-xl-2 { margin-top: 0.5rem !important; margin-bottom: 0.5rem !important; }
	.my-xl-3 { margin-top: 1rem !important; margin-bottom: 1rem !important; }
	.my-xl-4 { margin-top: 1.5rem !important; margin-bottom: 1.5rem !important; }
	.my-xl-5 { margin-top: 3rem !important; margin-bottom: 3rem !important; }
	.my-xl-auto { margin-top: auto !important; margin-bottom: auto !important; }
	.mt-xl-0 { margin-top: 0 !important; }
	.mt-xl-1 { margin-top: 0.25rem !important; }
	.mt-xl-2 { margin-top: 0.5rem !important; }
	.mt-xl-3 { margin-top: 1rem !important; }
	.mt-xl-4 { margin-top: 1.5rem !important; }
	.mt-xl-5 { margin-top: 3rem !important; }
	.mt-xl-auto { margin-top: auto !important; }
	.me-xl-0 { margin-right: 0 !important; }
	.me-xl-1 { margin-right: 0.25rem !important; }
	.me-xl-2 { margin-right: 0.5rem !important; }
	.me-xl-3 { margin-right: 1rem !important; }
	.me-xl-4 { margin-right: 1.5rem !important; }
	.me-xl-5 { margin-right: 3rem !important; }
	.me-xl-auto { margin-right: auto !important; }
	.mb-xl-0 { margin-bottom: 0 !important; }
	.mb-xl-1 { margin-bottom: 0.25rem !important; }
	.mb-xl-2 { margin-bottom: 0.5rem !important; }
	.mb-xl-3 { margin-bottom: 1rem !important; }
	.mb-xl-4 { margin-bottom: 1.5rem !important; }
	.mb-xl-5 { margin-bottom: 3rem !important; }
	.mb-xl-auto { margin-bottom: auto !important; }
	.ms-xl-0 { margin-left: 0 !important; }
	.ms-xl-1 { margin-left: 0.25rem !important; }
	.ms-xl-2 { margin-left: 0.5rem !important; }
	.ms-xl-3 { margin-left: 1rem !important; }
	.ms-xl-4 { margin-left: 1.5rem !important; }
	.ms-xl-5 { margin-left: 3rem !important; }
	.ms-xl-auto { margin-left: auto !important; }
	.p-xl-0 { padding: 0 !important; }
	.p-xl-1 { padding: 0.25rem !important; }
	.p-xl-2 { padding: 0.5rem !important; }
	.p-xl-3 { padding: 1rem !important; }
	.p-xl-4 { padding: 1.5rem !important; }
	.p-xl-5 { padding: 3rem !important; }
	.px-xl-0 { padding-right: 0 !important; padding-left: 0 !important; }
	.px-xl-1 { padding-right: 0.25rem !important; padding-left: 0.25rem !important; }
	.px-xl-2 { padding-right: 0.5rem !important; padding-left: 0.5rem !important; }
	.px-xl-3 { padding-right: 1rem !important; padding-left: 1rem !important; }
	.px-xl-4 { padding-right: 1.5rem !important; padding-left: 1.5rem !important; }
	.px-xl-5 { padding-right: 3rem !important; padding-left: 3rem !important; }
	.py-xl-0 { padding-top: 0 !important; padding-bottom: 0 !important; }
	.py-xl-1 { padding-top: 0.25rem !important; padding-bottom: 0.25rem !important; }
	.py-xl-2 { padding-top: 0.5rem !important; padding-bottom: 0.5rem !important; }
	.py-xl-3 { padding-top: 1rem !important; padding-bottom: 1rem !important; }
	.py-xl-4 { padding-top: 1.5rem !important; padding-bottom: 1.5rem !important; }
	.py-xl-5 { padding-top: 3rem !important; padding-bottom: 3rem !important; }
	.pt-xl-0 { padding-top: 0 !important; }
	.pt-xl-1 { padding-top: 0.25rem !important; }
	.pt-xl-2 { padding-top: 0.5rem !important; }
	.pt-xl-3 { padding-top: 1rem !important; }
	.pt-xl-4 { padding-top: 1.5rem !important; }
	.pt-xl-5 { padding-top: 3rem !important; }
	.pe-xl-0 { padding-right: 0 !important; }
	.pe-xl-1 { padding-right: 0.25rem !important; }
	.pe-xl-2 { padding-right: 0.5rem !important; }
	.pe-xl-3 { padding-right: 1rem !important; }
	.pe-xl-4 { padding-right: 1.5rem !important; }
	.pe-xl-5 { padding-right: 3rem !important; }
	.pb-xl-0 { padding-bottom: 0 !important; }
	.pb-xl-1 { padding-bottom: 0.25rem !important; }
	.pb-xl-2 { padding-bottom: 0.5rem !important; }
	.pb-xl-3 { padding-bottom: 1rem !important; }
	.pb-xl-4 { padding-bottom: 1.5rem !important; }
	.pb-xl-5 { padding-bottom: 3rem !important; }
	.ps-xl-0 { padding-left: 0 !important; }
	.ps-xl-1 { padding-left: 0.25rem !important; }
	.ps-xl-2 { padding-left: 0.5rem !important; }
	.ps-xl-3 { padding-left: 1rem !important; }
	.ps-xl-4 { padding-left: 1.5rem !important; }
	.ps-xl-5 { padding-left: 3rem !important; }
}

@media (min-width: 1400px) {
	.d-xxl-inline { display: inline !important; }
	.d-xxl-inline-block { display: inline-block !important; }
	.d-xxl-block { display: block !important; }
	.d-xxl-grid { display: grid !important; }
	.d-xxl-table { display: table !important; }
	.d-xxl-table-row { display: table-row !important; }
	.d-xxl-table-cell { display: table-cell !important; }
	.d-xxl-flex { display: flex !important; }
	.d-xxl-inline-flex { display: inline-flex !important; }
	.d-xxl-none { display: none !important; }
	.flex-xxl-fill { flex: 1 1 auto !important; }
	.flex-xxl-row { flex-direction: row !important; }
	.flex-xxl-column { flex-direction: column !important; }
	.flex-xxl-row-reverse { flex-direction: row-reverse !important; }
	.flex-xxl-column-reverse { flex-direction: column-reverse !important; }
	.flex-xxl-grow-0 { flex-grow: 0 !important; }
	.flex-xxl-grow-1 { flex-grow: 1 !important; }
	.flex-xxl-shrink-0 { flex-shrink: 0 !important; }
	.flex-xxl-shrink-1 { flex-shrink: 1 !important; }
	.flex-xxl-wrap { flex-wrap: wrap !important; }
	.flex-xxl-nowrap { flex-wrap: nowrap !important; }
	.flex-xxl-wrap-reverse { flex-wrap: wrap-reverse !important; }
	.justify-content-xxl-start { justify-content: flex-start !important; }
	.justify-content-xxl-end { justify-content: flex-end !important; }
	.justify-content-xxl-center { justify-content: center !important; }
	.justify-content-xxl-between { justify-content: space-between !important; }
	.justify-content-xxl-around { justify-content: space-around !important; }
	.justify-content-xxl-evenly { justify-content: space-evenly !important; }
	.align-items-xxl-start { align-items: flex-start !important; }
	.align-items-xxl-end { align-items: flex-end !important; }
	.align-items-xxl-center { align-items: center !important; }
	.align-items-xxl-baseline { align-items: baseline !important; }
	.align-items-xxl-stretch { align-items: stretch !important; }
	.align-content-xxl-start { align-content: flex-start !important; }
	.align-content-xxl-end { align-content: flex-end !important; }
	.align-content-xxl-center { align-content: center !important; }
	.align-content-xxl-between { align-content: space-between !important; }
	.align-content-xxl-around { align-content: space-around !important; }
	.align-content-xxl-stretch { align-content: stretch !important; }
	.align-self-xxl-auto { align-self: auto !important; }
	.align-self-xxl-start { align-self: flex-start !important; }
	.align-self-xxl-end { align-self: flex-end !important; }
	.align-self-xxl-center { align-self: center !important; }
	.align-self-xxl-baseline { align-self: baseline !important; }
	.align-self-xxl-stretch { align-self: stretch !important; }
	.order-xxl-first { order: -1 !important; }
	.order-xxl-0 { order: 0 !important; }
	.order-xxl-1 { order: 1 !important; }
	.order-xxl-2 { order: 2 !important; }
	.order-xxl-3 { order: 3 !important; }
	.order-xxl-4 { order: 4 !important; }
	.order-xxl-5 { order: 5 !important; }
	.order-xxl-last { order: 6 !important; }
	.m-xxl-0 { margin: 0 !important; }
	.m-xxl-1 { margin: 0.25rem !important; }
	.m-xxl-2 { margin: 0.5rem !important; }
	.m-xxl-3 { margin: 1rem !important; }
	.m-xxl-4 { margin: 1.5rem !important; }
	.m-xxl-5 { margin: 3rem !important; }
	.m-xxl-auto { margin: auto !important; }
	.mx-xxl-0 { margin-right: 0 !important; margin-left: 0 !important; }
	.mx-xxl-1 { margin-right: 0.25rem !important; margin-left: 0.25rem !important; }
	.mx-xxl-2 { margin-right: 0.5rem !important; margin-left: 0.5rem !important; }
	.mx-xxl-3 { margin-right: 1rem !important; margin-left: 1rem !important; }
	.mx-xxl-4 { margin-right: 1.5rem !important; margin-left: 1.5rem !important; }
	.mx-xxl-5 { margin-right: 3rem !important; margin-left: 3rem !important; }
	.mx-xxl-auto { margin-right: auto !important; margin-left: auto !important; }
	.my-xxl-0 { margin-top: 0 !important; margin-bottom: 0 !important; }
	.my-xxl-1 { margin-top: 0.25rem !important; margin-bottom: 0.25rem !important; }
	.my-xxl-2 { margin-top: 0.5rem !important; margin-bottom: 0.5rem !important; }
	.my-xxl-3 { margin-top: 1rem !important; margin-bottom: 1rem !important; }
	.my-xxl-4 { margin-top: 1.5rem !important; margin-bottom: 1.5rem !important; }
	.my-xxl-5 { margin-top: 3rem !important; margin-bottom: 3rem !important; }
	.my-xxl-auto { margin-top: auto !important; margin-bottom: auto !important; }
	.mt-xxl-0 { margin-top: 0 !important; }
	.mt-xxl-1 { margin-top: 0.25rem !important; }
	.mt-xxl-2 { margin-top: 0.5rem !important; }
	.mt-xxl-3 { margin-top: 1rem !important; }
	.mt-xxl-4 { margin-top: 1.5rem !important; }
	.mt-xxl-5 { margin-top: 3rem !important; }
	.mt-xxl-auto { margin-top: auto !important; }
	.me-xxl-0 { margin-right: 0 !important; }
	.me-xxl-1 { margin-right: 0.25rem !important; }
	.me-xxl-2 { margin-right: 0.5rem !important; }
	.me-xxl-3 { margin-right: 1rem !important; }
	.me-xxl-4 { margin-right: 1.5rem !important; }
	.me-xxl-5 { margin-right: 3rem !important; }
	.me-xxl-auto { margin-right: auto !important; }
	.mb-xxl-0 { margin-bottom: 0 !important; }
	.mb-xxl-1 { margin-bottom: 0.25rem !important; }
	.mb-xxl-2 { margin-bottom: 0.5rem !important; }
	.mb-xxl-3 { margin-bottom: 1rem !important; }
	.mb-xxl-4 { margin-bottom: 1.5rem !important; }
	.mb-xxl-5 { margin-bottom: 3rem !important; }
	.mb-xxl-auto { margin-bottom: auto !important; }
	.ms-xxl-0 { margin-left: 0 !important; }
	.ms-xxl-1 { margin-left: 0.25rem !important; }
	.ms-xxl-2 { margin-left: 0.5rem !important; }
	.ms-xxl-3 { margin-left: 1rem !important; }
	.ms-xxl-4 { margin-left: 1.5rem !important; }
	.ms-xxl-5 { margin-left: 3rem !important; }
	.ms-xxl-auto { margin-left: auto !important; }
	.p-xxl-0 { padding: 0 !important; }
	.p-xxl-1 { padding: 0.25rem !important; }
	.p-xxl-2 { padding: 0.5rem !important; }
	.p-xxl-3 { padding: 1rem !important; }
	.p-xxl-4 { padding: 1.5rem !important; }
	.p-xxl-5 { padding: 3rem !important; }
	.px-xxl-0 { padding-right: 0 !important; padding-left: 0 !important; }
	.px-xxl-1 { padding-right: 0.25rem !important; padding-left: 0.25rem !important; }
	.px-xxl-2 { padding-right: 0.5rem !important; padding-left: 0.5rem !important; }
	.px-xxl-3 { padding-right: 1rem !important; padding-left: 1rem !important; }
	.px-xxl-4 { padding-right: 1.5rem !important; padding-left: 1.5rem !important; }
	.px-xxl-5 { padding-right: 3rem !important; padding-left: 3rem !important; }
	.py-xxl-0 { padding-top: 0 !important; padding-bottom: 0 !important; }
	.py-xxl-1 { padding-top: 0.25rem !important; padding-bottom: 0.25rem !important; }
	.py-xxl-2 { padding-top: 0.5rem !important; padding-bottom: 0.5rem !important; }
	.py-xxl-3 { padding-top: 1rem !important; padding-bottom: 1rem !important; }
	.py-xxl-4 { padding-top: 1.5rem !important; padding-bottom: 1.5rem !important; }
	.py-xxl-5 { padding-top: 3rem !important; padding-bottom: 3rem !important; }
	.pt-xxl-0 { padding-top: 0 !important; }
	.pt-xxl-1 { padding-top: 0.25rem !important; }
	.pt-xxl-2 { padding-top: 0.5rem !important; }
	.pt-xxl-3 { padding-top: 1rem !important; }
	.pt-xxl-4 { padding-top: 1.5rem !important; }
	.pt-xxl-5 { padding-top: 3rem !important; }
	.pe-xxl-0 { padding-right: 0 !important; }
	.pe-xxl-1 { padding-right: 0.25rem !important; }
	.pe-xxl-2 { padding-right: 0.5rem !important; }
	.pe-xxl-3 { padding-right: 1rem !important; }
	.pe-xxl-4 { padding-right: 1.5rem !important; }
	.pe-xxl-5 { padding-right: 3rem !important; }
	.pb-xxl-0 { padding-bottom: 0 !important; }
	.pb-xxl-1 { padding-bottom: 0.25rem !important; }
	.pb-xxl-2 { padding-bottom: 0.5rem !important; }
	.pb-xxl-3 { padding-bottom: 1rem !important; }
	.pb-xxl-4 { padding-bottom: 1.5rem !important; }
	.pb-xxl-5 { padding-bottom: 3rem !important; }
	.ps-xxl-0 { padding-left: 0 !important; }
	.ps-xxl-1 { padding-left: 0.25rem !important; }
	.ps-xxl-2 { padding-left: 0.5rem !important; }
	.ps-xxl-3 { padding-left: 1rem !important; }
	.ps-xxl-4 { padding-left: 1.5rem !important; }
	.ps-xxl-5 { padding-left: 3rem !important; }
}

@media print {
	.d-print-inline { display: inline !important; }
	.d-print-inline-block { display: inline-block !important; }
	.d-print-block { display: block !important; }
	.d-print-grid { display: grid !important; }
	.d-print-table { display: table !important; }
	.d-print-table-row { display: table-row !important; }
	.d-print-table-cell { display: table-cell !important; }
	.d-print-flex { display: flex !important; }
	.d-print-inline-flex { display: inline-flex !important; }
	.d-print-none { display: none !important; }
}

.lds-ring {
	position: relative;

	display: inline-block;

	width: 80px;
	height: 80px;
}

.lds-ring div {
	position: absolute;

	display: block;

	box-sizing: border-box;
	width: 64px;
	height: 64px;
	margin: 8px;

	-webkit-animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;

	        animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;

	border: 8px solid #FFF;
	border-color: var(--main-color) transparent transparent transparent;
	border-radius: 50%;
}

.lds-ring div:nth-child(1) {
	-webkit-animation-delay: -0.45s;
	        animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
	-webkit-animation-delay: -0.3s;
	        animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
	-webkit-animation-delay: -0.15s;
	        animation-delay: -0.15s;
}

@-webkit-keyframes lds-ring {
	0% {
		-webkit-transform: rotate(0deg);
		        transform: rotate(0deg);
	}

	100% {
		-webkit-transform: rotate(360deg);
		        transform: rotate(360deg);
	}
}

@keyframes lds-ring {
	0% {
		-webkit-transform: rotate(0deg);
		        transform: rotate(0deg);
	}

	100% {
		-webkit-transform: rotate(360deg);
		        transform: rotate(360deg);
	}
}

.menu {
	min-height: 106px;

	background-color: #FFF;

	box-shadow: 0 6px 12px rgba(0, 0, 0, 0.06);
}

.menu__fixed {
	position: fixed;
	z-index: 9;
	top: 0;
	left: 0;

	width: 100%;

	background-color: rgba(250, 250, 250, 0.5);
	box-shadow: 0 6px 12px rgba(0, 0, 0, 6%);
	-webkit-backdrop-filter: blur(10px);
	        backdrop-filter: blur(10px);
}

.menu__wrapper {
	display: flex;
	align-items: center;
	justify-content: space-between;

	padding: 25px 0;

	transition: all 0.1s;
}

.menu__nav-wrapper,
.menu__nav-wrapper .menu__list {
	display: flex;
	align-items: center;
	justify-content: space-between;

	margin: 0;
	padding: 0;

	list-style-type: none;
}

.menu__list li {
	margin-right: 50px;
}

.menu__list li a {
	position: relative;

	text-decoration: none;

	color: var(--second-color);

	font-size: 17px;

	font-weight: 500;
	line-height: 20px;
}

.menu__list li a img {
	display: none;
}

.menu__list li a span,
.menu__list li a span svg {
	display: none;
}

.menu__list li a::after {
	position: absolute;
	right: 0;
	left: 0;

	display: block;

	width: 0;
	height: 2px;
	margin: 0 auto;

	content: '';
	transition: var(--transition-anime);

	opacity: 0;
	background-color: var(--second-color);
}

.menu__list li:hover a::after {
	width: 100%;

	opacity: 1;
}

.menu__list li a.active_link::after {
	width: 100%;

	opacity: 1;
}

.menu__dropdown.active {
	display: block;

	-webkit-animation: dropdown 0.2s ease;

	        animation: dropdown 0.2s ease;
}

.menu__dropdown li {
	cursor: pointer;
	-webkit-user-select: none;
	        user-select: none;
	text-transform: uppercase;

	color: #FAFAFA;

	font-size: 17px;
	font-weight: 500;
	line-height: 20px;
}

@-webkit-keyframes dropdown {
	from {
		-webkit-transform: translateY(5%);
		        transform: translateY(5%);
	}

	to {
		-webkit-transform: translateY(0);
		        transform: translateY(0);
	}
}

@keyframes dropdown {
	from {
		-webkit-transform: translateY(5%);
		        transform: translateY(5%);
	}

	to {
		-webkit-transform: translateY(0);
		        transform: translateY(0);
	}
}

.menu__dropdown {
	position: absolute;
	left: -3px;

	display: none;

	margin: 0;
	padding: 4px 8px;

	list-style-type: none;

	cursor: pointer;
	-webkit-user-select: none;
	        user-select: none;

	border-radius: 10px;
	background-color: var(--main-color);
}

.menu__lang {
	position: relative;
	z-index: 1;

	width: 45px;
	margin-right: 30px;
}

.menu__lang span {
	cursor: pointer;
	-webkit-user-select: none;
	        user-select: none;
	transition: var(--transition-anime);
	text-transform: uppercase;

	color: var(--second-color);

	font-size: 17px;
	font-weight: 600;
}

.menu__lang span svg {
	margin-left: 5px;
}

.menu__button {
	padding: 8px 20px;

	transition: var(--transition-anime);
	text-decoration: none;

	letter-spacing: 0.02em;
	text-transform: uppercase;

	color: white;
	border: 1px solid transparent;
	border-radius: 10px;
	background-color: var(--second-color);

	font-size: 14px;
	font-weight: 600;
	line-height: 17px;
}

.menu__button:hover {
	color: var(--second-color);
	border: 1px solid var(--second-color);
	background-color: transparent;
}

.menu__button svg {
	fill: white;

	display: none;

	width: 20px;
	height: 15px;
	margin-right: 5px;
	margin-bottom: 2px;
}

.menu__button:hover svg {
	fill: var(--second-color);
}

.menu__user {
	margin-right: 30px;

	cursor: pointer;

	border-radius: 100%;
	background-color: #ECEFF1;
}

.menu__user a {
	display: block;

	width: 30px;
	height: 30px;

	border-radius: 100%;
}

.menu__user a img {
	width: 100%;
	height: 100%;

	border-radius: 100%;

	object-fit: cover;
	object-position: center;
}

.menu__open {
	display: none;
}

.menu__close {
	display: none;
}

@media (max-width: 1200px) {
	.menu__user {
		margin-right: 25px;
	}

	.menu__user a svg {
		width: 22px;
		height: 26px;
	}

	.menu__button {
		padding: 5px 13px;

		font-size: 14px;
	}

	.menu__lang {
		width: 40px;
		margin-right: 25px;
	}

	.menu__list li {
		margin-right: 25px;
	}

	.menu__list li a {
		font-size: 15px;
	}
}

@media (max-width: 992px) {
	.menu__list li a span,
	.menu__list li a span svg {
		display: inline-block;
	}

	.menu__button {
		margin-right: 5px;
		padding: 0;

		text-transform: capitalize;

		color: var(--second-color);
		border: 1px solid transparent;
		background-color: unset;

		font-size: 18px;

		font-weight: 500;
		line-height: 27px;
	}

	.menu__button:hover {
		border: 1px solid transparent;
		background-color: unset;
	}

	.menu__button svg {
		fill: var(--second-color);

		display: unset;

		width: 26px;
		height: 30px;
		margin-right: 10px;
	}

	.menu__dropdown {
		position: absolute;
		top: -221px;
		left: 196px;

		display: flex;

		background-color: transparent;
	}

	.menu__user {
		margin-right: 0;
		margin-bottom: 20px;
		padding: 0;

		border-radius: unset;
		background-color: transparent;
	}

	.menu__user a {
		position: relative;

		width: 24px;
		height: 26px;
	}

	.menu__user a::after {
		position: absolute;
		top: 8px;
		left: 36px;

		width: -webkit-max-content;

		width: max-content;

		content: attr(data-label);

		color: var(--second-color);

		font-size: 18px;
		font-weight: 500;
		line-height: 18px;
	}

	.menu__user a {
		display: block;

		text-decoration: none;
	}

	.menu__user a svg {
		width: 29px;
		height: 24px;
		fill: var(--second-color);

		margin-right: 18px;
	}

	.menu__list li a.active_link {
		color: var(--main-color);
	}

	.menu__list li a.active_link img {
		-webkit-filter: invert(47%) sepia(97%) saturate(473%) hue-rotate(338deg) brightness(104%) contrast(89%);
		        filter: invert(47%) sepia(97%) saturate(473%) hue-rotate(338deg) brightness(104%) contrast(89%);
	}

	.menu__list li a.active_link svg {
		fill: var(--main-color);
	}

	.menu__user a.active::after {
		color: var(--main-color);
	}

	.menu__button.active {
		color: var(--main-color);
	}

	.menu__button.active svg {
		fill: var(--main-color);
	}

	.menu__dropdown li {
		padding-right: 10px;

		transition: all 0.5s;

		color: black;
	}

	.menu__dropdown li.active {
		color: var(--main-color);
	}

	.menu__open {
		display: block;
	}

	.menu__close {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.menu__close ul {
		display: flex;
		align-items: center;

		margin: 0;
		padding: 0;

		list-style-type: none;
	}

	.menu__close ul li {
		margin-left: 20px;

		text-transform: uppercase;

		color: var(--second-color);

		font-size: 17px;
		font-weight: 500;
		line-height: 20px;
	}

	.menu__close ul li.active {
		color: var(--main-color);
	}

	.menu__lang {
		display: none;
	}

	.menu__dropdown.active {
		display: flex;
	}

	.menu__nav-wrapper {
		align-items: initial;
		flex-direction: column;
		justify-content: flex-start;

		width: 275px;
		height: 100%;
		padding: 25px 12px;

		background-color: #FFF;
	}

	.menu__nav-wrapper .menu__list {
		align-items: initial;
		flex-direction: column;
		justify-content: initial;

		margin-top: 30px;
	}

	.menu__list li {
		margin-bottom: 20px;
	}

	.menu__list li a {
		display: flex;
		align-items: center;

		font-size: 19px;
	}

	.menu__list li a::after {
		content: unset;
	}

	.menu__list li a img {
		display: block;

		width: 24px;
		height: 30px;
		margin-right: 12px;
		-webkit-filter: invert(0.8);
		        filter: invert(0.8);
		object-fit: contain;
	}

	.menu__list li a span {
		margin-right: 12px;
	}

	.menu__list li a span svg {
		width: 24px;
		height: 30px;
	}

	.menu__nav {
		position: fixed;
		z-index: 3;
		top: 0;
		left: -100%;

		visibility: hidden;

		width: 100%;
		height: 100vh;

		transition: all 0.3s ease;

		opacity: 0;
	}

	.nav__overlay {
		position: fixed;
		z-index: -1;
		top: 0;
		left: 0;

		visibility: hidden;

		width: 100%;
		height: 100vh;

		transition: all 0.3s ease;

		opacity: 0;
		background-color: rgba(0, 0, 0, 0.5);
	}

	.menu__nav.active {
		left: 0;

		visibility: visible;

		opacity: 1;
	}

	.menu__nav.active .nav__overlay {
		visibility: visible;

		opacity: 1;
	}
}

@media (max-width: 576px) {
	.menu {
		min-height: 75px;
	}

	.menu__wrapper {
		padding: 9px 0;
	}
}

.footer {
  padding: 60px 0;

  background-color: #f8f8f8;
}

.footer__wrapper {
  display: grid;
  grid-template-columns: 150px 236px 308px 300px;
  grid-column-gap: 123px;
  grid-column-gap: 100px;
  -webkit-column-gap: 100px;
          column-gap: 100px;
}

.footer_logo {
  display: block;
}

.footer_logo svg {
  width: 98px;
  height: 108px;
}

.footer_licence {
  margin-top: 12px;

  text-align: left;

  color: #3b2f43;

  font-size: 11px;
  font-weight: 500;
  line-height: 12px;
}

.footer_licence:last-child {
  margin-top: 5px;
}

.footer_menu {
  margin: 0;
  padding: 0;

  list-style-type: none;
}

.footer_menu li a {
  text-decoration: none;

  color: var(--second-color);

  font-size: 17px;
  font-weight: 500;
  line-height: 20px;
}

.footer_menu li {
  padding-bottom: 12px;
}

.footer_menu li:last-child {
  padding-bottom: 0;
}

.social_title {
  color: var(--second-color);

  font-size: 17px;

  font-weight: 500;
  line-height: 28px;
}

.social {
  display: flex;
  align-items: center;
  grid-gap: 6px;
  gap: 6px;

  margin-top: 15px;
}

.social a {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 30px;
  height: 30px;
  margin-right: 12px;

  transition: var(--transition-anime);
  text-align: center;
  vertical-align: middle;

  border: 1px solid transparent;
  border-radius: 100%;
}

.social a:hover {
  -webkit-transform: translateY(-8%);
          transform: translateY(-8%);
}

.social a:hover svg {
  fill: black;
}

.footer_phone,
.footer_mail {
  display: block;

  transition: var(--transition-anime);
  text-decoration: none;

  color: var(--second-color);

  font-size: 25px;

  font-weight: 600;
  line-height: 30px;
}

.footer_phone:hover,
.footer_mail:hover {
  color: var(--main-color);
}

.footer_mail {
  margin-top: 8px;

  font-size: 25px;
  line-height: 32px;
}

.datasitelogo {
  display: flex;
  align-items: center;

  margin-top: 18px;

  text-decoration: none;
}

.datasitelogo span {
  margin-left: 10px;
  padding-right: 14px;

  color: var(--second-color);

  font-size: 12px;

  font-weight: normal;
  line-height: 16px;
}

@media (max-width: 1399px) {
  .footer__wrapper {
    grid-column-gap: 62px;
    -webkit-column-gap: 62px;
            column-gap: 62px;
  }
}

@media (max-width: 1200px) {
  .footer__wrapper {
    grid-column-gap: 54px;
    -webkit-column-gap: 54px;
            column-gap: 54px;
    grid-template-columns: 139px 150px 240px 271px;
  }

  .footer_menu li a {
    font-size: 15px;
  }

  .footer_menu li {
    padding-bottom: 7px;
  }

  .social_title {
    font-size: 14px;
    line-height: 24px;
  }

  .footer_mail {
    font-size: 22px;
    line-height: 23px;
  }
}

.call {
  color: var(--second-color);

  font-size: 17px;
  font-weight: 500;
  line-height: 20px;
}

@media (max-width: 992px) {
  .footer__wrapper {
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 60px;
    row-gap: 60px;
  }
}

@media (max-width: 576px) {
  .footer {
    padding: 30px 0;
  }

  .footer_menu li {
    padding-bottom: 4px;
  }

  .footer_menu li a {
    font-size: 14px;
  }

  .footer_licence {
    text-align: center;
  }

  .footer__wrapper div {
    justify-self: center;

    text-align: center;
  }

  .footer_logo,
  .footer_menu li {
    text-align: center;
  }

  .footer__wrapper {
    grid-template-columns: 1fr;
    grid-row-gap: 15px;
    row-gap: 15px;
  }

  .social {
    justify-content: center;
  }

  .social_wrapper {
    width: 276px;

    text-align: center;
  }

  .footer_phone {
    font-size: 16px;
  }

  .call {
    margin-bottom: 0;

    font-size: 15px;
  }

  .footer_mail {
    margin-top: 0;

    font-size: 16px;
    line-height: 18px;
  }
}

.app-auth {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-template-rows: 100%;

	min-height: 100vh;
}

.error {
	margin-top: 10px;

	transition: all 0.3s linear;

	color: tomato;

	font-size: 12px;
}

.app-auth div {
	background-repeat: no-repeat;
	background-size: cover;
}

.app-auth__right {
	position: relative;

	display: flex;
	align-items: center;
	justify-content: center;
}

.app-auth__wrapper {
	width: 508px;
	min-height: 200px;
	padding: 30px;

	border-radius: 20px;
	background-color: #EEE;
}

.app-auth__wrapper label {
	position: relative;

	display: flex;
	flex-direction: column;

	margin-bottom: 20px;

	color: #3B2F43;

	font-size: 17px;
	font-weight: 500;
}

.app-auth__wrapper label:nth-child(2) {
	margin-bottom: 10px;
}

.app-auth__wrapper label:last-child {
	margin-bottom: 0 !important;
}

.app-auth__wrapper label input {
	padding: 14px;

	color: var(--second-color);
	border: 1px solid rgba(59, 47, 67, 0.4);
	border-radius: 10px;
	outline: none;

	font-size: 17px;
	font-weight: normal;
	line-height: 20px;
}

.app-auth__flex {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.app-auth__flex div,
.app-auth__flex div a {
	color: rgba(59, 47, 67, 0.7);

	font-size: 14px;
	font-weight: 500;
}

.error_messages {
	margin-top: 5px;
	padding-left: 8px;

	color: red;

	font-size: 12.5px;
}

.app-auth__flex div a {
	color: var(--second-color);
}

.app-auth__wrapper label .eyes {
	position: absolute;
	top: 37px;
	right: 14px;

	cursor: pointer;
	-webkit-user-select: none;
	        user-select: none;
}

.app-auth__button {
	width: 100%;
	margin-top: 20px;
	padding: 14px;

	text-align: center;

	color: white;
	border: 1px solid transparent;
	border-radius: 10px;
	outline: none;
	background-color: #3B2F43;

	font-size: 17px;
	font-weight: 600;
	line-height: 20px;
}

.app-auth__link {
	display: block;

	margin-top: 20px;

	transition: all 0.5s;
	text-align: center;

	color: var(--second-color);

	font-size: 17px;
	font-weight: 600;
	font-style: normal;
	line-height: 20px;
}

.app-auth__link:hover {
	color: unset;
}

.app-auth__image {
	display: block;

	width: 120px;
	height: auto;
	margin: 50px auto;
}

.app-auth__dots {
	position: absolute;

	display: grid;

	width: 70px;
	height: 30px;
	grid-template-columns: repeat(4, 1fr);
	grid-template-rows: auto;
}

.app-auth__dots.top {
	top: 30px;
	right: 30px;
}

.app-auth__dots.bottom {
	bottom: 30px;
	left: 30px;
}

.app-auth__dots img {
	-webkit-animation: animateImg 1s linear infinite alternate;
	        animation: animateImg 1s linear infinite alternate;
}

.app-auth__dots img:nth-child(1),
.app-auth__dots img:nth-child(5) {
	-webkit-animation-delay: 0.3s;
	        animation-delay: 0.3s;
}

.app-auth__dots img:nth-child(2),
.app-auth__dots img:nth-child(6) {
	-webkit-animation-delay: 0.6s;
	        animation-delay: 0.6s;
}

.app-auth__dots img:nth-child(3),
.app-auth__dots img:nth-child(7) {
	-webkit-animation-delay: 0.9s;
	        animation-delay: 0.9s;
}

.app-auth__dots img:nth-child(4),
.app-auth__dots img:nth-child(8) {
	-webkit-animation-delay: 1.2s;
	        animation-delay: 1.2s;
}

@-webkit-keyframes animateImg {
	from {
		-webkit-transform: scale(1);
		        transform: scale(1);
	}

	to {
		-webkit-transform: scale(1.3);
		        transform: scale(1.3);
	}
}

@keyframes animateImg {
	from {
		-webkit-transform: scale(1);
		        transform: scale(1);
	}

	to {
		-webkit-transform: scale(1.3);
		        transform: scale(1.3);
	}
}

input:disabled,
button:disabled {
	cursor: not-allowed;
}

button:disabled {
	background-color: rgba(0, 0, 0, 0.4);
}

.policy {
	align-items: center;
	flex-direction: row !important;
	justify-content: space-between;

	margin-bottom: 0 !important;

	cursor: pointer;
	-webkit-user-select: none;
	        user-select: none;

	font-size: 12px !important;
}

.policy input[type='checkbox'] {
	display: inline-block;

	width: 16px;
	height: 16px;
	margin-right: 6px;

	border: 1px solid rgba(59, 47, 67, 0.7);
	border-radius: 4px;
}

.timer {
	color: rgba(59, 47, 67, 0.7);

	font-size: 12px;
	font-weight: 500;
	line-height: 14px;
}

.timer span {
	cursor: pointer;
}

.app-auth__wrapper h4 {
	margin-bottom: 21px;

	color: #3B2F43;

	font-size: 25px;
	font-weight: 600;
	line-height: 30px;
}

@media (max-width: 1200px) {
	.app-auth__left {
		display: none;
	}

	.app-auth__right {
		grid-column: 1 / 3;
	}

	.app-auth__wrapper {
		width: 478px;
		padding: 40px;
	}
}

@media (max-width: 576px) {
	.app-auth {
		padding: 0 15px;
	}

	.app-auth__wrapper {
		width: 100%;
		padding: 25px 15px;
	}

	.app-auth__wrapper h4 {
		margin-bottom: 11px;

		font-size: 16px;
		line-height: 20px;
	}

	.app-auth__wrapper label {
		font-size: 13px;
	}

	.app-auth__wrapper label input {
		padding: 10px;

		font-size: 14px;
		line-height: 16px;
	}

	.app-auth__flex {
		flex-wrap: wrap;
	}

	.policy {
		margin-top: 7px !important;
		margin-bottom: 10px !important;
	}

	.app-auth__wrapper label .eyes {
		top: 29px;
	}

	.app-auth__wrapper label .eyes svg {
		height: 18px;
	}

	.app-auth__dots.top {
		top: 15px;
		right: -10px;
	}

	.app-auth__dots.bottom {
		bottom: 10px;
		left: 0;
	}

	.app-auth__button {
		padding: 13px;

		font-size: 15px;
		line-height: 12px;
	}

	.app-auth__link {
		font-size: 14px;
		line-height: 6px;
	}

	.app-auth__flex div a {
		display: inline-block;

		margin-top: 10px;
	}
}

.trigger {
	padding: 7px 13px;

	text-align: center;

	color: #FFF;
	border: none;
	border-radius: 5px;
	outline: none;
	background: #3E3E3E;

	font-family: cursive;
	font-size: 15px;
}

.close-button {
	float: right;

	width: 1.5rem;

	cursor: pointer;
	text-align: center;

	border-radius: 0.25rem;
	background-color: lightgray;

	line-height: 1.5rem;
}

.close-button:hover {
	background-color: darkgray;
}

.show-modal {
	visibility: visible;

	transition: visibility 0s linear 0s, opacity 0.25s 0s, -webkit-transform 0.25s;

	transition: visibility 0s linear 0s, opacity 0.25s 0s, transform 0.25s;

	transition: visibility 0s linear 0s, opacity 0.25s 0s, transform 0.25s, -webkit-transform 0.25s;
	-webkit-transform: scale(1);
	        transform: scale(1);

	opacity: 1;
}

.user-menu {
	height: 100vh;
	padding: 66px 27px;

	background-color: #FAFAFA;
}

.user-menu ul {
	margin: 0;
	padding: 0;

	list-style-type: none;
}

.user-menu ul li a {
	display: flex;
	align-items: center;

	padding: 16px 21px;

	transition: all 0.3s linear;
	text-decoration: none;

	color: #BCBCBC;
	border-radius: 20px;

	font-size: 21px;
	font-weight: 600;
	line-height: 25px;
}

.user-menu ul li a svg {
	margin-right: 51px;
}

.user-menu ul li a:hover,
.user-menu ul li a.user-active {
	color: white;
	background-color: var(--second-color);
}

.user-menu ul li a:hover svg,
.user-menu ul li a.user-active svg {
	fill: white;
}

.user-menu__close {
	display: none;
}

@media (max-width: 992px) {
	.user-menu {
		position: fixed;
		z-index: 1;
		top: 75px;
		left: -100%;

		padding: 70px 24px 150px 24px;

		transition: all 0.5s linear;
	}

	.user-menu.active {
		left: 0;

		overflow: auto;
	}

	.user-menu ul li a {
		display: inline-flex;

		padding: 15px 10px;
	}

	.user-menu ul li a svg {
		margin-right: 15px;
	}

	.user-menu ul li a {
		font-size: 18px;
	}

	.user-menu__close {
		display: inline-block;

		margin-top: 18px;

		border-radius: 5px;
		background-color: var(--second-color);
	}

	.user-menu__close svg {
		fill: white;
	}

	.user-menu .user-menu__close {
		position: relative;

		margin-top: 0;
	}

	.user-menu .user-menu__close svg {
		position: absolute;
		top: -65px;
		right: -220px;

		border-radius: 5px;
		background-color: var(--second-color);
	}
}

.user-overlay__wrapper {
	display: grid;
	grid-template-columns: 370px 1fr;
	grid-template-rows: 100%;
}

.fill_balance {
	margin-bottom: 10px;
	padding: 10px 20px;

	color: #FAFAFA;
	border: none;
	border-radius: 10px;
	outline: none;
	background-color: var(--second-color);

	font-size: 21px;
	font-weight: 600;
	line-height: 25px;
}

.balance {
	display: block;

	width: 198px;
	margin-left: auto;

	text-align: right;

	color: var(--second-color);

	font-size: 14px;
	line-height: 16px;
}

.user__spinner {
	position: relative;

	height: 300px;
}

.user__spinner > div {
	position: absolute;
	top: 50%;
	left: 50%;

	-webkit-transform: translate(-50%, -50%);

	        transform: translate(-50%, -50%);
}

@media (min-width: 1200px) {
	.custom_container {
		max-width: 100%;
	}
}

@media (max-width: 992px) {
	.custom_container {
		width: 720px;
		margin: 0 auto;
	}

	.user-overlay__wrapper {
		grid-template-columns: 1fr;
		grid-template-rows: unset;
	}
}

@media (max-width: 768px) {
	.custom_container {
		width: 540px;
		margin: 0 auto;
		padding: 0 15px;
	}
}

@media (max-width: 576px) {
	.custom_container {
		width: 100%;
		margin: 0 auto;
		padding: 31px 15px;
	}
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
}

.overlay {
  background-color: #0000009b;
  width: 100%;
  height: 100%;
}

.content {
  padding: 60px 120px;
  background: #fff;
  border-radius: 0 0 16px 16px;
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.btn {
  padding: 8px 20px;

  transition: var(--transition-anime);
  text-decoration: none;

  letter-spacing: 0.02em;
  text-transform: uppercase;

  color: white;
  border: 1px solid transparent;
  border-radius: 10px;
  background-color: var(--second-color);

  font-size: 14px;
  font-weight: 600;
  line-height: 17px;

  margin: 15px auto 0 auto;
}

.wrapper {
  padding: 80px 60px 80px 44px;
}

.delete-btn {
  padding: 8px 20px;

  transition: var(--transition-anime);
  text-decoration: none;

  letter-spacing: 0.02em;
  text-transform: uppercase;

  color: white;
  border: 1px solid transparent;
  border-radius: 10px;
  background-color: var(--second-color);

  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  margin-top: 16px;
}

