/*!
 * Bootstrap Reboot v5.0.2 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 * Forked from Normalize.css, licensed MIT (https://github.com/necolas/normalize.css/blob/master/LICENSE.md)
 */*,
::after,
::before { box-sizing: border-box; }

@media (prefers-reduced-motion: no-preference) { :root { scroll-behavior: smooth; } }

body {
	margin: 0;

	color: #212529;
	background-color: #FFF;

	font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	-webkit-text-size-adjust: 100%;
	-webkit-tap-highlight-color: transparent;
}

hr {
	margin: 1rem 0;

	opacity: 0.25;
	color: inherit;
	border: 0;
	background-color: currentColor;
}
hr:not([size]) { height: 1px; }

h1,
h2,
h3,
h4,
h5,
h6 {
	margin-top: 0;
	margin-bottom: 0.5rem;

	font-weight: 500;
	line-height: 1.2;
}
h1 { font-size: calc(1.375rem + 1.5vw); }

@media (min-width: 1200px) { h1 { font-size: 2.5rem; } }
h2 { font-size: calc(1.325rem + 0.9vw); }

@media (min-width: 1200px) { h2 { font-size: 2rem; } }
h3 { font-size: calc(1.3rem + 0.6vw); }

@media (min-width: 1200px) { h3 { font-size: 1.75rem; } }
h4 { font-size: calc(1.275rem + 0.3vw); }

@media (min-width: 1200px) { h4 { font-size: 1.5rem; } }
h5 { font-size: 1.25rem; }
h6 { font-size: 1rem; }
p { margin-top: 0; margin-bottom: 1rem; }

abbr[data-bs-original-title],
abbr[title] { cursor: help; -webkit-text-decoration: underline dotted; text-decoration: underline dotted; -webkit-text-decoration-skip-ink: none; text-decoration-skip-ink: none; }

address {
	margin-bottom: 1rem;

	font-style: normal;
	line-height: inherit;
}

ol,
ul { padding-left: 2rem; }

dl,
ol,
ul { margin-top: 0; margin-bottom: 1rem; }

ol ol,
ol ul,
ul ol,
ul ul { margin-bottom: 0; }
dt { font-weight: 700; }
dd { margin-bottom: 0.5rem; margin-left: 0; }
blockquote { margin: 0 0 1rem; }

b,
strong { font-weight: bolder; }
small { font-size: 0.875em; }

mark {
	padding: 0.2em;

	background-color: #FCF8E3;
}

sub,
sup {
	position: relative;

	vertical-align: baseline;

	font-size: 0.75em;
	line-height: 0;
}
sub { bottom: -0.25em; }
sup { top: -0.5em; }

a {
	text-decoration: underline;

	color: #0D6EFD;
}
a:hover { color: #0A58CA; }

a:not([href]):not([class]),
a:not([href]):not([class]):hover {
	text-decoration: none;

	color: inherit;
}

code,
kbd,
pre,
samp { font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; font-size: 1em; direction: ltr; unicode-bidi: bidi-override; }

pre {
	display: block;
	overflow: auto;

	margin-top: 0;
	margin-bottom: 1rem;

	font-size: 0.875em;
}

pre code {
	word-break: normal;

	color: inherit;

	font-size: inherit;
}

code {
	word-wrap: break-word;

	color: #D63384;

	font-size: 0.875em;
}
a > code { color: inherit; }

kbd {
	padding: 0.2rem 0.4rem;

	color: #FFF;
	border-radius: 0.2rem;
	background-color: #212529;

	font-size: 0.875em;
}

kbd kbd {
	padding: 0;

	font-size: 1em;
	font-weight: 700;
}
figure { margin: 0 0 1rem; }

img,
svg { vertical-align: middle; }

table {
	caption-side: bottom;

	border-collapse: collapse;
}

caption {
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;

	text-align: left;

	color: #6C757D;
}
th { text-align: inherit; text-align: -webkit-match-parent; }

tbody,
td,
tfoot,
th,
thead,
tr { border-width: 0; border-style: solid; border-color: inherit; }
label { display: inline-block; }
button { border-radius: 0; }
button:focus:not(:focus-visible) { outline: 0; }

button,
input,
optgroup,
select,
textarea {
	margin: 0;

	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
}

button,
select { text-transform: none; }
[role=button] { cursor: pointer; }
select { word-wrap: normal; }
select:disabled { opacity: 1; }
[list]::-webkit-calendar-picker-indicator { display: none; }

[type=button],
[type=reset],
[type=submit],
button { -webkit-appearance: button; }

[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled),
button:not(:disabled) { cursor: pointer; }

::-moz-focus-inner {
	padding: 0;

	border-style: none;
}
textarea { resize: vertical; }

fieldset {
	min-width: 0;
	margin: 0;
	padding: 0;

	border: 0;
}

legend {
	float: left;

	width: 100%;
	margin-bottom: 0.5rem;
	padding: 0;

	font-size: calc(1.275rem + 0.3vw);
	line-height: inherit;
}

@media (min-width: 1200px) { legend { font-size: 1.5rem; } }
legend + * { clear: left; }

::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-year-field { padding: 0; }
::-webkit-inner-spin-button { height: auto; }
[type=search] { outline-offset: -2px; -webkit-appearance: textfield; }
::-webkit-search-decoration { -webkit-appearance: none; }
::-webkit-color-swatch-wrapper { padding: 0; }
::file-selector-button { font: inherit; }
::-webkit-file-upload-button { font: inherit; -webkit-appearance: button; }
output { display: inline-block; }
iframe { border: 0; }

summary {
	display: list-item;

	cursor: pointer;
}
progress { vertical-align: baseline; }
[hidden] { display: none !important; }
