.app-auth {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-template-rows: 100%;

	min-height: 100vh;
}

.error {
	margin-top: 10px;

	transition: all 0.3s linear;

	color: tomato;

	font-size: 12px;
}

.app-auth div {
	background-repeat: no-repeat;
	background-size: cover;
}

.app-auth__right {
	position: relative;

	display: flex;
	align-items: center;
	justify-content: center;
}

.app-auth__wrapper {
	width: 508px;
	min-height: 200px;
	padding: 30px;

	border-radius: 20px;
	background-color: #EEE;
}

.app-auth__wrapper label {
	position: relative;

	display: flex;
	flex-direction: column;

	margin-bottom: 20px;

	color: #3B2F43;

	font-size: 17px;
	font-weight: 500;
}

.app-auth__wrapper label:nth-child(2) {
	margin-bottom: 10px;
}

.app-auth__wrapper label:last-child {
	margin-bottom: 0 !important;
}

.app-auth__wrapper label input {
	padding: 14px;

	color: var(--second-color);
	border: 1px solid rgba(59, 47, 67, 0.4);
	border-radius: 10px;
	outline: none;

	font-size: 17px;
	font-weight: normal;
	line-height: 20px;
}

.app-auth__flex {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.app-auth__flex div,
.app-auth__flex div a {
	color: rgba(59, 47, 67, 0.7);

	font-size: 14px;
	font-weight: 500;
}

.error_messages {
	margin-top: 5px;
	padding-left: 8px;

	color: red;

	font-size: 12.5px;
}

.app-auth__flex div a {
	color: var(--second-color);
}

.app-auth__wrapper label .eyes {
	position: absolute;
	top: 37px;
	right: 14px;

	cursor: pointer;
	user-select: none;
}

.app-auth__button {
	width: 100%;
	margin-top: 20px;
	padding: 14px;

	text-align: center;

	color: white;
	border: 1px solid transparent;
	border-radius: 10px;
	outline: none;
	background-color: #3B2F43;

	font-size: 17px;
	font-weight: 600;
	line-height: 20px;
}

.app-auth__link {
	display: block;

	margin-top: 20px;

	transition: all 0.5s;
	text-align: center;

	color: var(--second-color);

	font-size: 17px;
	font-weight: 600;
	font-style: normal;
	line-height: 20px;
}

.app-auth__link:hover {
	color: unset;
}

.app-auth__image {
	display: block;

	width: 120px;
	height: auto;
	margin: 50px auto;
}

.app-auth__dots {
	position: absolute;

	display: grid;

	width: 70px;
	height: 30px;
	grid-template-columns: repeat(4, 1fr);
	grid-template-rows: auto;
}

.app-auth__dots.top {
	top: 30px;
	right: 30px;
}

.app-auth__dots.bottom {
	bottom: 30px;
	left: 30px;
}

.app-auth__dots img {
	animation: animateImg 1s linear infinite alternate;
}

.app-auth__dots img:nth-child(1),
.app-auth__dots img:nth-child(5) {
	animation-delay: 0.3s;
}

.app-auth__dots img:nth-child(2),
.app-auth__dots img:nth-child(6) {
	animation-delay: 0.6s;
}

.app-auth__dots img:nth-child(3),
.app-auth__dots img:nth-child(7) {
	animation-delay: 0.9s;
}

.app-auth__dots img:nth-child(4),
.app-auth__dots img:nth-child(8) {
	animation-delay: 1.2s;
}

@keyframes animateImg {
	from {
		transform: scale(1);
	}

	to {
		transform: scale(1.3);
	}
}

input:disabled,
button:disabled {
	cursor: not-allowed;
}

button:disabled {
	background-color: rgba(0, 0, 0, 0.4);
}

.policy {
	align-items: center;
	flex-direction: row !important;
	justify-content: space-between;

	margin-bottom: 0 !important;

	cursor: pointer;
	user-select: none;

	font-size: 12px !important;
}

.policy input[type='checkbox'] {
	display: inline-block;

	width: 16px;
	height: 16px;
	margin-right: 6px;

	border: 1px solid rgba(59, 47, 67, 0.7);
	border-radius: 4px;
}

.timer {
	color: rgba(59, 47, 67, 0.7);

	font-size: 12px;
	font-weight: 500;
	line-height: 14px;
}

.timer span {
	cursor: pointer;
}

.app-auth__wrapper h4 {
	margin-bottom: 21px;

	color: #3B2F43;

	font-size: 25px;
	font-weight: 600;
	line-height: 30px;
}

@media (max-width: 1200px) {
	.app-auth__left {
		display: none;
	}

	.app-auth__right {
		grid-column: 1 / 3;
	}

	.app-auth__wrapper {
		width: 478px;
		padding: 40px;
	}
}

@media (max-width: 576px) {
	.app-auth {
		padding: 0 15px;
	}

	.app-auth__wrapper {
		width: 100%;
		padding: 25px 15px;
	}

	.app-auth__wrapper h4 {
		margin-bottom: 11px;

		font-size: 16px;
		line-height: 20px;
	}

	.app-auth__wrapper label {
		font-size: 13px;
	}

	.app-auth__wrapper label input {
		padding: 10px;

		font-size: 14px;
		line-height: 16px;
	}

	.app-auth__flex {
		flex-wrap: wrap;
	}

	.policy {
		margin-top: 7px !important;
		margin-bottom: 10px !important;
	}

	.app-auth__wrapper label .eyes {
		top: 29px;
	}

	.app-auth__wrapper label .eyes svg {
		height: 18px;
	}

	.app-auth__dots.top {
		top: 15px;
		right: -10px;
	}

	.app-auth__dots.bottom {
		bottom: 10px;
		left: 0;
	}

	.app-auth__button {
		padding: 13px;

		font-size: 15px;
		line-height: 12px;
	}

	.app-auth__link {
		font-size: 14px;
		line-height: 6px;
	}

	.app-auth__flex div a {
		display: inline-block;

		margin-top: 10px;
	}
}

.trigger {
	padding: 7px 13px;

	text-align: center;

	color: #FFF;
	border: none;
	border-radius: 5px;
	outline: none;
	background: #3E3E3E;

	font-family: cursive;
	font-size: 15px;
}

.close-button {
	float: right;

	width: 1.5rem;

	cursor: pointer;
	text-align: center;

	border-radius: 0.25rem;
	background-color: lightgray;

	line-height: 1.5rem;
}

.close-button:hover {
	background-color: darkgray;
}

.show-modal {
	visibility: visible;

	transition: visibility 0s linear 0s, opacity 0.25s 0s, transform 0.25s;
	transform: scale(1);

	opacity: 1;
}
