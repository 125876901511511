.user-menu {
	height: 100vh;
	padding: 66px 27px;

	background-color: #FAFAFA;
}

.user-menu ul {
	margin: 0;
	padding: 0;

	list-style-type: none;
}

.user-menu ul li a {
	display: flex;
	align-items: center;

	padding: 16px 21px;

	transition: all 0.3s linear;
	text-decoration: none;

	color: #BCBCBC;
	border-radius: 20px;

	font-size: 21px;
	font-weight: 600;
	line-height: 25px;
}

.user-menu ul li a svg {
	margin-right: 51px;
}

.user-menu ul li a:hover,
.user-menu ul li a.user-active {
	color: white;
	background-color: var(--second-color);
}

.user-menu ul li a:hover svg,
.user-menu ul li a.user-active svg {
	fill: white;
}

.user-menu__close {
	display: none;
}

@media (max-width: 992px) {
	.user-menu {
		position: fixed;
		z-index: 1;
		top: 75px;
		left: -100%;

		padding: 70px 24px 150px 24px;

		transition: all 0.5s linear;
	}

	.user-menu.active {
		left: 0;

		overflow: auto;
	}

	.user-menu ul li a {
		display: inline-flex;

		padding: 15px 10px;
	}

	.user-menu ul li a svg {
		margin-right: 15px;
	}

	.user-menu ul li a {
		font-size: 18px;
	}

	.user-menu__close {
		display: inline-block;

		margin-top: 18px;

		border-radius: 5px;
		background-color: var(--second-color);
	}

	.user-menu__close svg {
		fill: white;
	}

	.user-menu .user-menu__close {
		position: relative;

		margin-top: 0;
	}

	.user-menu .user-menu__close svg {
		position: absolute;
		top: -65px;
		right: -220px;

		border-radius: 5px;
		background-color: var(--second-color);
	}
}
