.lds-ring {
	position: relative;

	display: inline-block;

	width: 80px;
	height: 80px;
}

.lds-ring div {
	position: absolute;

	display: block;

	box-sizing: border-box;
	width: 64px;
	height: 64px;
	margin: 8px;

	animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;

	border: 8px solid #FFF;
	border-color: var(--main-color) transparent transparent transparent;
	border-radius: 50%;
}

.lds-ring div:nth-child(1) {
	animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
	animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
	animation-delay: -0.15s;
}

@keyframes lds-ring {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}
