.wrapper {
  padding: 80px 60px 80px 44px;
}

.delete-btn {
  padding: 8px 20px;

  transition: var(--transition-anime);
  text-decoration: none;

  letter-spacing: 0.02em;
  text-transform: uppercase;

  color: white;
  border: 1px solid transparent;
  border-radius: 10px;
  background-color: var(--second-color);

  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  margin-top: 16px;
}
